import mapVerify from '@/utils/formValidate'
import { t } from '@/libs_sz/locale'

const filterFormater = (data, value) => {
  const datas = [{ label: t('lang.gles.common.all'), value: 0 }].concat(data)
  return datas?.find(t => t.value === value)?.label ?? value
}

const formatOptions = data => { return [{ label: t('lang.gles.common.all'), value: 0 }].concat(data) }

export const getMoreQueryFormData = (that, row) => {
  const moreQueryData = [
    // 配置编码
    {
      name: 'configCode',
      span: 12,
      component: 'elInput',
      label: that.$t('lang.gles.common.code')
    },
    // 单据类型
    {
      name: 'receiptsType',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.common.receiptsType'),
      clearable: true,
      data: formatOptions(that.receiptTypeList)
    },
    // 工厂
    {
      name: 'factoryId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.factory'),
      clearable: true,
      filterable: true,
      data: formatOptions(that.factoryList || []),
      mchange: that.handleFactoryChange
    },
    // 车间
    {
      name: 'workshopId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.workshop'),
      clearable: true,
      filterable: true,
      disabled: !!that.$refs.myQueryView?.$refs.myForm?.$refs.moreQueryData?.formModel.warehouseId,
      data: formatOptions(that.workshopList || []),
      mchange: that.handleWorkshopChange
    },
    // 仓库
    {
      name: 'warehouseId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.warehouse'),
      clearable: true,
      disabled: !!that.$refs.myQueryView?.$refs.myForm?.$refs.moreQueryData?.formModel.workshopId,
      data: formatOptions(that.warehouseList || []),
      mchange: that.handleWarehouseChange
    },
    // 区域
    {
      name: 'areaId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.common.area'),
      clearable: true,
      data: formatOptions(that.areaList || []),
      mchange: that.handleAreaChange
    },
    // 库区
    {
      name: 'warehouseAreaId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.warehouseArea'),
      clearable: true,
      data: formatOptions(that.warehouseAreaList || []),
      mchange: that.handleWarehouseAreaChange
    },
    // 生产库位
    {
      name: 'productionFactoryPositionId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.base.factoryPositionTypeProduce'),
      clearable: true,
      data: formatOptions(that.factoryPositionList || [])
    },
    // 产线
    {
      name: 'productionLineId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.common.productionLine'),
      clearable: true,
      data: formatOptions(that.productionLineList || []),
      mchange: that.handleProductionLineChange
    },
    // 设备
    {
      name: 'deviceId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.common.device'),
      clearable: true,
      data: formatOptions(that.deviceList || []),
      mchange: that.handleDeviceChange
    },
    // 设备货位
    {
      name: 'deviceGoodsAllocationId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.base.deviceGoodsPosition'),
      clearable: true,
      data: formatOptions(that.deviceGoodsPositionList || [])
    },
    // 货位类型
    {
      name: 'goodsPositionType',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.base.goodsPositionType'),
      clearable: true,
      data: [{ label: t('lang.gles.common.all'), value: '0' }].concat(that.goodsPositionTypeList || [])
    },
    // 移动容器类型
    {
      name: 'moveContainerTypeId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.common.moveContainerType'),
      clearable: true,
      data: formatOptions(that.moveContainerTypeList || [])
    },
    // 周转容器类型
    {
      name: 'turnoverContainerTypeId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.material.turnoverContainerType'),
      clearable: true,
      data: formatOptions(that.turnoverContainerTypeList || [])
    },
    // 物料一级分类
    {
      name: 'materialFirstClassifyId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.common.materialFirstClassify'),
      clearable: true,
      data: formatOptions(that.materialClassifyFirstList || []),
      mchange: that.handleClassifyFirstChange
    },
    // 物料二级分类
    {
      name: 'materialSecondClassifyId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.common.materialSecondClassify'),
      clearable: true,
      data: formatOptions(that.classifySecondList || []),
      mchange: that.handleClassifySecondChange
    },
    // 物料编码
    {
      name: 'materialId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.material.materialCode'),
      data: formatOptions(that.materialCodeList || [])
    },
    // 等级状态
    {
      name: 'gradeStatus',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.common.gradeStatus'),
      clearable: true,
      data: formatOptions(that.gradeStatusList || [])
    },
    // 策略
    {
      name: 'strategyId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.common.strategy'),
      clearable: true,
      data: that.strategy_dict || []
    },
    // status
    {
      name: 'status',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.base.status'),
      clearable: true,
      data: that.statusFlagList || []
    }
  ]
  return moreQueryData
}
// 查询列表列表
export const getSearchTableItem = (that, options) => {
  const searchTableItem = [
    // 配置编码
    {
      prop: 'configCode',
      label: that.$t('lang.gles.common.code'),
      sortable: true,
      minWidth: 100,
      isShow: true
    },
    // 单据类型
    {
      prop: 'receiptsType',
      label: that.$t('lang.gles.common.receiptsType'),
      formatter(row, column) {
        return filterFormater(that.receiptTypeList, row[column])
      },
      isShow: true
    },
    // 工厂
    {
      prop: 'factoryId',
      label: that.$t('lang.gles.strategy.factory'),
      formatter(row, column) {
        return filterFormater(that.factoryList, row[column])
      },
      isShow: true
    },
    // 车间
    {
      prop: 'workshopId',
      label: that.$t('lang.gles.strategy.workshop'),
      formatter(row, column) {
        return filterFormater(that.workshoplist, row[column])
      },
      isShow: true
    },
    // 仓库
    {
      prop: 'warehouseId',
      label: that.$t('lang.gles.strategy.warehouse'),
      formatter(row, column) {
        return filterFormater(that.warehouselist, row[column])
      },
      isShow: true
    },
    // 区域
    {
      prop: 'areaId',
      label: that.$t('lang.gles.common.area'),
      formatter(row, column) {
        return filterFormater(that.arealist, row[column])
      },
      isShow: true
    },
    // 库区
    {
      prop: 'warehouseAreaId',
      label: that.$t('lang.gles.strategy.warehouseArea'),
      formatter(row, column) {
        return filterFormater(that.warehouseArealist, row[column])
      },
      isShow: true
    },
    // 生产库位
    {
      prop: 'productionFactoryPositionId',
      label: that.$t('lang.gles.base.factoryPositionTypeProduce'),
      formatter(row, column) {
        return filterFormater(that.factoryPositionlist, row[column])
      },
      isShow: true
    },
    // 产线
    {
      prop: 'productionLineId',
      label: that.$t('lang.gles.common.productionLine'),
      formatter(row, column) {
        return filterFormater(that.productionLinelist, row[column])
      },
      isShow: true
    },
    // 设备
    {
      prop: 'deviceId',
      label: that.$t('lang.gles.common.device'),
      formatter(row, column) {
        return filterFormater(that.devicelist, row[column])
      },
      isShow: true
    },
    // 设备货位
    {
      prop: 'deviceGoodsAllocationId',
      label: that.$t('lang.gles.base.deviceGoodsPosition'),
      formatter(row, column) {
        return filterFormater(that.deviceGoodsPositionlist, row[column])
      },
      isShow: true
    },
    // 货位类型
    {
      prop: 'goodsPositionType',
      label: that.$t('lang.gles.base.goodsPositionType'),
      formatter(row, column) {
        return filterFormater(that.goodsPositionTypeList, Number(row[column]))
      },
      isShow: true
    },
    // 移动容器类型
    {
      prop: 'moveContainerTypeId',
      label: that.$t('lang.gles.common.moveContainerType'),
      formatter(row, column) {
        return filterFormater(that.moveContainerTypeList, row[column])
      },
      isShow: true
    },
    // 周转容器类型
    {
      prop: 'turnoverContainerTypeId',
      label: that.$t('lang.gles.material.turnoverContainerType'),
      formatter(row, column) {
        return filterFormater(that.turnoverContainerTypeList, row[column])
      },
      isShow: true
    },
    // 物料一级分类
    {
      prop: 'materialFirstClassifyId',
      label: that.$t('lang.gles.common.materialFirstClassify'),
      formatter(row, column) {
        return filterFormater(that.materialClassifyFirstList, row[column])
      },
      isShow: true
    },
    // 物料二级分类
    {
      prop: 'materialSecondClassifyId',
      label: that.$t('lang.gles.common.materialSecondClassify'),
      formatter(row, column) {
        return filterFormater(that.materialClassifySecondList || [], row[column])
      },
      isShow: true
    },
    // 物料编码
    {
      prop: 'materialId',
      label: that.$t('lang.gles.material.materialCode'),
      formatter(row, column) {
        return filterFormater(that.materialCodelist || [], row[column])
      },
      isShow: true
    },
    // 等级状态
    {
      prop: 'gradeStatus',
      label: that.$t('lang.gles.common.gradeStatus'),
      formatter(row, column) {
        return filterFormater(that.gradeStatusList, row[column])
      },
      isShow: true
    },
    // 策略
    {
      prop: 'strategyId',
      label: that.$t('lang.gles.common.strategy'),
      minWidth: 120,
      formatter(row, column) {
        if (row[column]) {
          const enumArr = []
          row[column].split(',').forEach(item => {
            if (that.strategy_dict.find(cv => cv.value === Number(item))) {
              enumArr.push(that.strategy_dict.find(cv => cv.value === Number(item)).label)
            }
          })
          return enumArr.join(',')
        }
        return row[column]
      },
      isShow: true
    },
    // 状态
    {
      prop: 'status',
      label: that.$t('lang.gles.common.statusFlag'),
      slotName: 'enableStatus',
      width: '90px',
      isShow: true
    },
    // 备注
    {
      prop: 'remark',
      label: that.$t('lang.gles.strategy.remark'),
      isShow: true
    }
  ]
  searchTableItem.forEach((item) => (item.showOverflowTooltip = true))
  return searchTableItem
}

// 编辑弹框 基础信息
export const getEditBaseFormData = (that, row) => {
  const editBaseInfoFormData = [
    // 编码
    {
      name: 'configCode',
      value: row.configCode || '',
      span: 6,
      component: 'elInput',
      label: that.$t('lang.gles.common.code'),
      rules: mapVerify(['required', 'inputCode']),
      appendSlotName: 'inputCodeTipIcon',
      clearable: true,
      class: 'tip-icon'
    },
    // 备注
    {
      name: 'remark',
      value: row.remark || '',
      span: 6,
      component: 'elInput',
      label: that.$t('lang.gles.strategy.remark'),
      rules: mapVerify(['inputLen500']),
      clearable: true
    }

  ]
  return editBaseInfoFormData
}
// 匹配条件
export const getMatchConditionFormData = (that, options, row) => {
  const matchConditionFormData = [
    // 单据类型
    {
      name: 'receiptsType',
      value: row.receiptsType || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.common.receiptsType'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(options.receiptsTypeList || [])
    },
    // 工厂
    {
      name: 'factoryId',
      value: row.factoryId || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.factory'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(options.factoryList || []),
      mchange: that.handleFactoryChange
    },
    // 车间
    {
      name: 'workshopId',
      value: row.workshopId || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.workshop'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(that.workshopList || []),
      disabled: !!that.$refs.myMatchForm?.formModel.warehouseId,
      mchange: that.handleWorkshopChange
    },
    // 仓库
    {
      name: 'warehouseId',
      value: row.warehouseId || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.warehouse'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(that.warehouseList || []),
      disabled: !!that.$refs.myMatchForm?.formModel.workshopId,
      mchange: that.handleWarehouseChange
    },
    // 区域
    {
      name: 'areaId',
      value: row.areaId || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.common.area'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(that.areaList || []),
      mchange: that.handleAreaChange
    },
    // 库区
    {
      name: 'warehouseAreaId',
      value: row.warehouseAreaId || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.warehouseArea'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(that.warehouseAreaList || []),
      mchange: that.handleWarehouseAreaChange
    },
    // 生产库位
    {
      name: 'productionFactoryPositionId',
      value: row.productionFactoryPositionId || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.base.factoryPositionTypeProduce'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(that.factoryPositionList || [])
    },
    // 产线
    {
      name: 'productionLineId',
      value: row.productionLineId || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.common.productionLine'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(that.productionLineList || []),
      mchange: that.handleProductionLineChange
    },
    // 设备
    {
      name: 'deviceId',
      value: row.deviceId || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.common.device'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(that.deviceList || []),
      mchange: that.handleDeviceChange
    },
    // 设备货位
    {
      name: 'deviceGoodsAllocationId',
      value: row.deviceGoodsAllocationId || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.base.deviceGoodsPosition'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(that.deviceGoodsPositionList || [])
    },
    // 货位类型
    {
      name: 'goodsPositionType',
      value: row.goodsPositionType || '0',
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.base.goodsPositionType'),
      rules: mapVerify(['required']),
      clearable: true,
      data: [{ label: t('lang.gles.common.all'), value: '0' }].concat(options.goodsPositionTypeList || [])
    },
    // 移动容器类型
    {
      name: 'moveContainerTypeId',
      value: row.moveContainerTypeId || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.common.moveContainerType'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(options.moveContainerTypeList || [])
    },
    // 周转容器类型
    {
      name: 'turnoverContainerTypeId',
      value: row.turnoverContainerTypeId || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.material.turnoverContainerType'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(options.turnoverContainerTypeList || [])
    },
    // 物料一级分类
    {
      name: 'materialFirstClassifyId',
      value: row.materialFirstClassifyId || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.common.materialFirstClassify'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(options.materialClassifyFirstList || []),
      mchange: that.handleClassifyFirstChange
    },
    // 物料二级分类
    {
      name: 'materialSecondClassifyId',
      value: row.materialSecondClassifyId || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.common.materialSecondClassify'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(that.classifySecondList || []),
      mchange: that.handleClassifySecondChange
    },
    // 物料编码
    {
      name: 'materialId',
      value: row.materialId || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.material.materialCode'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(that.materialCodeList || [])
    },
    // 等级状态
    {
      name: 'gradeStatus',
      value: row.gradeStatus ?? 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.common.gradeStatus'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(options.gradeStatusList || [])
    }
  ]
  return matchConditionFormData
}
// 添加策略table
export const getStrategyTableData = (that, options) => {
  const strategyTableData = [
    {
      prop: 'strategyCode',
      label: that.$t('lang.gles.strategy.strategyCode'),
      formatter(row, column) {
        return filterFormater(options.strategyCode_dict, row[column])
      }
    },
    {
      prop: 'strategyName',
      label: that.$t('lang.gles.strategy.strategyName'),
      formatter(row, column) {
        return filterFormater(options.strategy_dict, row[column])
      }
    },
    {
      prop: 'remark',
      label: that.$t('lang.gles.strategy.remark')
    }
  ]
  strategyTableData.forEach((item) => (item.showOverflowTooltip = true))
  return strategyTableData
}
// 可编辑子table的form表单 ---策略
export const editStrategyFormData = (that, options) => {
  const strategyFormData = [
    {
      name: 'strategyCode',
      value: '',
      component: 'elSelect',
      clearable: true,
      data: options.strategyCode_dict || []
    },
    {
      name: 'strategyName',
      value: '',
      component: 'elSelect',
      clearable: true,
      data: options.strategy_dict || []
    },
    {
      name: 'remark',
      value: '',
      component: 'elInput',
      clearable: true
    }
  ]
  return strategyFormData
}

export const getStrategyFormData = (that, options) => {
  const strategyFormData = [
    // 策略编码
    {
      name: 'strategyCode',
      value: '',
      span: 20,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.strategyCode'),
      rules: mapVerify(['required']),
      data: options.strategyCode_dict || [],
      clearable: true,
      mchange(val, item, cb) {
        val ? cb({ strategyName: options.strategy_dict.find(item => item.value === val).value }) : cb({ strategyName: '' })
      }
    },
    // 策略名称
    {
      name: 'strategyName',
      value: '',
      span: 20,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.strategyName'),
      data: options.strategy_dict || [],
      clearable: true,
      mchange(val, item, cb) {
        val ? cb({ strategyCode: options.strategy_dict.find(item => item.value === val).value }) : cb({ strategyCode: '' })
      }
    }

  ]
  return strategyFormData
}

