<template>
  <div class="shelf-strategy">
    <el-tabs v-model="activeName" class="tabs" @tab-click="handleClick">
      <el-tab-pane v-for="item in tabListConf" :key="item.component" :label="$t(item.label)" :name="item.component">
        <!-- <keep-alive :include="['ShelfStrategy']">
          <component :is="item.component" v-if="activeName === item.component" ref="comps" />
        </keep-alive> -->
        <ApplicationConfig v-if="item.component === 'ApplicationConfig'" :key="item.id"/>
        <ShelfStrategy v-else :key="item.id"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import ApplicationConfig from './applicationConfig/applicationConfig.vue'
import ShelfStrategy from './shelfStrategy/ShelfStrategy.vue'
export default {
  components: {
    ApplicationConfig,
    ShelfStrategy
  },
  data() {
    return {
      activeName: sessionStorage.getItem('activeName') || 'ShelfStrategy',
      tabListConf: [
        { id:0, label: this.$t('lang.gles.strategy.applicationConfig'), component: 'ApplicationConfig' },
        { id:1,label: this.$t('lang.gles.strategy.putAwayStrategy'), component: 'ShelfStrategy' }
      ]
    }
  },
  methods: {
    handleClick(tab, event) {
      // console.log(this.$refs.comps)
      if (tab.index === '0') {
        sessionStorage.setItem('activeName', 'ApplicationConfig')
        // // 获取一下最新添加的策略
        // this.$nextTick(() => {
        //   this.$refs.comps[0].currentCom = 'appConfigList'
        //   this.$refs.comps[0].$refs.appConfig.getStrategyList()
        // })
      } else {
        // this.$refs.comps[1].currentCom = 'ShelfStrategyList'
        sessionStorage.setItem('activeName', 'ShelfStrategy')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.shelf-strategy {
  /deep/ .el-tabs__header {
    margin: 10px;
    padding: 10px 0;
    background-color: #fff;
    .el-tabs__item {
      width: 120px;
      font-weight: 800;
      text-align: center;
    }
  }
}
</style>
