<template>
  <!-- <component
    :is="currentCom"
    ref="appConfig"
    :mode="mode"
    :row-detail.sync="row"
    :options="options"
    @updateCom="updateCom"
  /> -->
  <div>
    <appConfigList 
      v-if="currentCom === 'appConfigList'" 
      ref="appConfig"
      :mode="mode"
      :row-detail.sync="row"
      :options="options"
      @updateCom="updateCom"
      key="0"/>
    <appConfigAdd v-else 
      ref="appConfig"
      :mode="mode"
      :row-detail.sync="row"
      :options="options"
      @updateCom="updateCom"
      key="1"/>
  </div> 
</template>
<script>
import appConfigList from './components/list.vue'
import appConfigAdd from './components/add.vue'
export default {
  name: 'ShelfStragy',
  components: {
    appConfigList,
    appConfigAdd
  },
  data() {
    return {
      currentCom: 'appConfigList',
      mode: '',
      row: {},
      options: {}
    }
  },
  methods: {
    updateCom({ currentCom, mode, row, options }) {
      if(document.readyState === 'complete'){
        // console.log(document.readyState)
        // components 加载组件的时间 大于 直接渲染组件的时间
        // console.time()
        // console.timeEnd()
      }
      this.currentCom = currentCom
      this.mode = mode
      this.row = row
      this.options = options
    }
  }
}
</script>
<style lang="scss" scoped></style>
