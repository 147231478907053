<template>
  <el-dialog
    :title="handleMode === 'edit' ? `${$t('lang.gles.common.edit')}${$t('lang.gles.strategy.targetRange')}` : $t('lang.gles.strategy.addTargetRange')"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
    width="30%"
    @closed="reset"
  >
    <m-form
      ref="myRangeForm"
      :form-data="addTargetRangeFormData"
      label-position="right"
      :label-width="120"
      :extend-config="extendFormConfig"
    >
      <template #inputCodeTipIcon>
        <el-tooltip
          popper-class="workshop_code_tip-icon"
          effect="dark"
          :content="row.targetTypeCode === 11 ? $t('lang.gles.strategy.needSetStockInSeqForGoodsPosition') : $t('lang.gles.strategy.needSetStockInSeqForFactoryPosition')"
          placement="right"
        >
          <i class="el-icon-tip el-icon-question tip" />
        </el-tooltip>
      </template>
    </m-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">{{ $t('lang.gles.common.cancel') }}</el-button>
      <el-button
        type="primary"
        @click="targetRangeSubmit"
      >{{ $t('lang.gles.common.confirm') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import MForm from '@/libs_sz/components/MForm/MForm.vue'
import { getAddTargetRangeFormData } from '../data'
import addMixins from '@/mixins/addMixins'
import commonMixins from '@/mixins/commonMixins'
export default {
  name: 'AddTargetRange',
  components: { MForm },
  mixins: [addMixins, commonMixins],
  props: {
  },
  data() {
    return {
      visible: false,
      // form表单相关的
      extendFormConfig: {
        isNeedBtn: false
      },
      handleMode: 'add',
      row: {},
      rowDetails: {}
    }
  },
  computed: {
    addTargetRangeFormData() {
      return getAddTargetRangeFormData(this, { ...this.row }, { ...this.rowDetails })
    }
  },
  created() {
    this.initSelectList()
  },
  methods: {
    init(row, mode, rowDetail) {
      this.visible = true
      this.row = row
      this.$nextTick(() => {
        this.handleMode = mode
        if (this.handleMode === 'edit') {
          const { row } = rowDetail
          if (row.factoryPositionId) {
            rowDetail.row.factoryPositionId = [Number(row.factoryPositionId)]
          }
          if (row.goodsPositionId) {
            rowDetail.row.goodsPositionId = [Number(row.goodsPositionId)]
          }
          this.rowDetails = rowDetail
          const params = { factoryId: row.factoryId }
          this.getWorkshopList(params)
          this.getWarehouseList(params)
          this.getWarehouseAreaList({ warehouseId: row.warehouseId, workshopId: row.workshopId })
          const param = { warehouseAreaId: row.warehouseAreaId }
          if ((this.row.targetTypeCode === 11 && this.row.targetRangeCode !== 14) || this.row.targetRangeCode === 13) {
            this.getFixGoodsPositionList(param)
          } else {
            this.getFactoryPositionList(param)
          }
          this.$refs.myRangeForm.changeFormValue(row ?? {})
        }
      })
    },
    /**
     * 初始化下拉列表
     */
    initSelectList() {
      this.getFactoryList()
      // this.getWorkshopList()
      // this.getWarehouseList()
      // this.getWarehouseAreaList()
    },
    /**
     * 所属工厂 change
     * 工厂-车间
     */
    handleFactoryChange(val, formItem, changeFormValue, formModel) {
      // console.log(this.$refs.myRangeForm.formModel,"thisssssssssss")
      this.$set(this.$refs.myRangeForm.formModel, formItem.name, val)
      this.$refs.myRangeForm.formModel.workshopId = ''
      this.$refs.myRangeForm.formModel.warehouseId = ''
      this.$refs.myRangeForm.formModel.warehouseAreaId = ''
      this.$refs.myRangeForm.formModel.goodsPositionId = []
      this.$refs.myRangeForm.formModel.factoryPositionId = []
      const params = { factoryId: this.$refs.myRangeForm.formModel.factoryId }
      this.getWorkshopList(params)
      this.getWarehouseList(params)
      this.getWarehouseAreaList()
    },
    /**
     * 所属车间 change
     * 车间-库区
     */
    handleWorkshopChange(val, formItem, changeFormValue, formModel) {
      val ? this.$refs.myRangeForm.clearValidate('warehouseId') : ''
      this.$set(this.$refs.myRangeForm.formModel, formItem.name, val)
      this.$refs.myRangeForm.formModel.warehouseAreaId = ''
      const params = { workshopId: this.$refs.myRangeForm.formModel.workshopId }
      this.getWarehouseAreaList(params)
    },
    /**
     * 所属仓库 change
     * 仓库-库区
     */
    handleWarehouseChange(val, formItem, changeFormValue, formModel) {
      // console.log(val,")_____",this.$refs.myRangeForm)
      val ? this.$refs.myRangeForm.clearValidate('workshopId') : ''
      this.$set(this.$refs.myRangeForm.formModel, formItem.name, val)
      this.$refs.myRangeForm.formModel.warehouseAreaId = ''
      const params = { warehouseId: this.$refs.myRangeForm.formModel.warehouseId }
      this.getWarehouseAreaList(params)
    },

    /**
     * 库区 change
     * 库区---库位和固定货位
     */
    handleWarehouseAreaChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.$refs.myRangeForm.formModel, formItem.name, val)
      this.$refs.myRangeForm.formModel.goodsPositionId = []
      this.$refs.myRangeForm.formModel.factoryPositionId = []
      const params = { warehouseAreaId: this.$refs.myRangeForm.formModel.warehouseAreaId }
      // console.log(this.row,"0____")
      if ((this.row.targetTypeCode === 11 && this.row.targetRangeCode !== 14) || this.row.targetRangeCode === 13) {
        this.getFixGoodsPositionList(params)
      } else {
        this.getFactoryPositionList(params)
      }
    },
    handleFactoryPositionChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.$refs.myRangeForm.formModel, formItem.name, val)
      this.$refs.myRangeForm.formModel.distributeRule = ''
    },
    handleChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.$refs.myRangeForm.formModel, formItem.name, val)
    },
    reset() {
      delete this.mode
      this.$refs.myRangeForm.resetForm()
    },
    // 表单提交
    targetRangeSubmit() {
      this.$refs.myRangeForm.getValidateFormModel(true).then(async(model) => {
        // console.log(this.handleMode, 'model', model)
        const formModelArr = []
        if (model.goodsPositionId && model.goodsPositionId.length > 0) {
          model.goodsPositionId.forEach(item => {
            formModelArr.push({ ...model, goodsPositionId: item })
          })
        }
        if (model.factoryPositionId && model.factoryPositionId.length > 0) {
          model.factoryPositionId.forEach(item => {
            formModelArr.push({ ...model, factoryPositionId: item })
          })
        }
        if (!model.goodsPositionId && !model.factoryPositionId) {
          formModelArr.push(model)
        }
        // console.log(formModelArr,"arrrrrrr")
        if (this.handleMode === 'edit') {
          this.$emit('addRange', formModelArr, this.rowDetails.$index)
        } else {
          this.$emit('addRange', formModelArr)
        }
        this.visible = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-dialog__footer{
    text-align: center;
}
.tip{
  display: inline-block;
  position:absolute;
  top: 6px;
  right:-24px;
}
</style>
