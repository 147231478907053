var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"basic-data-add"},[_c('el-card',{staticClass:"mb10"},[_c('div',{staticClass:"form_title b mb15"},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.common.basicInfo'))+" ")]),_c('m-form',{ref:"myForm",attrs:{"form-data":_vm.basicFormData,"label-position":"right","label-width":120,"extend-config":_vm.extendFormConfig},scopedSlots:_vm._u([{key:"inputCodeTipIcon",fn:function(){return [_c('el-tooltip',{attrs:{"popper-class":"workshop_code_tip-icon","effect":"dark","content":_vm.$t(_vm.autoGeneCodeTip),"placement":"right"}},[_c('i',{staticClass:"el-icon-tip el-icon-question tip"})])]},proxy:true}])}),_c('el-button',{staticClass:"ml20",attrs:{"type":"primary"},on:{"click":_vm.onCancel}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.common.back'))+" ")])],1),(_vm.isShowAll)?_c('el-card',{staticClass:"add-card"},[_c('div',{staticClass:"form_title b mb15"},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.strategy.strategyDetail'))+" ")]),(_vm.showTargetRange)?_c('div',{staticClass:"targetRange"},[_c('el-divider',{attrs:{"content-position":"left"}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.strategy.targetRange'))+" ")]),(_vm.formModel.targetRangeCode === 11 && !!_vm.formModel.targetTypeCode)?_c('div',{staticClass:"ml30"},[_c('el-form',{ref:"form1",attrs:{"model":_vm.ruleForm,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":_vm.$t('lang.gles.strategy.distributeRule')}},[_c('el-select',{attrs:{"placeholder":_vm.$t('lang.gles.common.pleaseSelect'),"clearable":""},model:{value:(_vm.ruleForm.distributeRule),callback:function ($$v) {_vm.$set(_vm.ruleForm, "distributeRule", $$v)},expression:"ruleForm.distributeRule"}},[_c('el-option',{attrs:{"label":_vm.$t('lang.gles.strategy.stockInSequential'),"value":10}})],1),_c('el-tooltip',{attrs:{"popper-class":"workshop_code_tip-icon","effect":"dark","content":_vm.formModel.targetTypeCode === 10
                  ? _vm.$t('lang.gles.strategy.needSetStockInSeqForFactoryPosition')
                  : _vm.$t('lang.gles.strategy.needSetStockInSeqForGoodsPosition'),"placement":"right"}},[_c('i',{staticClass:"el-icon-tip el-icon-question ml5"})])],1)],1)],1):_c('div',{staticClass:"btns ml30"},[_c('el-button',{attrs:{"type":"text","icon":"el-icon-circle-plus"},on:{"click":_vm.addTargetRange}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.strategy.add'))+" ")]),_c('el-button',{staticStyle:{"color":"#f56c6c"},attrs:{"type":"text","icon":"el-icon-delete-solid"},on:{"click":_vm.batchDelRange}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.common.batchDelete'))+" ")]),_c('m-table',{ref:"targetRangeEditTable",attrs:{"border":"","table-data":_vm.targetRangeTableData,"extend-config":_vm.extendTargetTableConfig,"table-item":_vm.targetRangeTableItem,"page-data":null},on:{"delRangeRow":_vm.delRangeRow,"mEditTableEditRow":_vm.editTargetRow,"upRow":_vm.upRow,"downRow":_vm.downRow,"selection-change":function (val) { return (_vm.selectTargetRange = val); }}})],1)],1):_vm._e(),(_vm.formModel.targetRangeCode === 13 ||
        _vm.formModel.targetRangeCode === 14 ||
        ((_vm.formModel.targetRangeCode === 10 ||
          _vm.formModel.targetRangeCode === 11) &&
          (_vm.formModel.targetTypeCode === 11 ||
            _vm.formModel.targetTypeCode === 12))
      )?_c('div',{staticClass:"targetRange"},[_c('el-divider',{attrs:{"content-position":"left"}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.strategy.shelf.containerTypeFilter'))+" ")]),_c('div',{staticClass:"btns ml30"},[_c('el-button',{attrs:{"type":"text","icon":"el-icon-circle-plus"},on:{"click":_vm.addTypeFilter}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.strategy.add'))+" ")]),_c('el-button',{staticStyle:{"color":"#f56c6c"},attrs:{"type":"text","icon":"el-icon-delete-solid"},on:{"click":_vm.batchDelTypeFilter}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.common.batchDelete'))+" ")]),_c('edit-table',{ref:"containerFilterEditTable",attrs:{"table-data":_vm.containerFilterTableData,"table-item":_vm.containerFilterTableItem,"extend-config":_vm.extendTableConfig},on:{"update:tableData":function($event){_vm.containerFilterTableData=$event},"update:table-data":function($event){_vm.containerFilterTableData=$event},"editRowItem":_vm.editRow,"removeRowItem":_vm.delTypeFilterRow,"selection-change":function (val) { return (_vm.selectTypeFilter = val); }}})],1)],1):_vm._e(),_c('div',{staticClass:"limitCondition"},[_c('el-divider',{attrs:{"content-position":"left"}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.strategy.shelf.limitCondition'))+" ")]),_c('el-form',{ref:"form",attrs:{"model":_vm.formLimit,"label-width":"120px"}},[(
            ((_vm.formModel.targetRangeCode === 10 ||
              _vm.formModel.targetRangeCode === 11) &&
              (_vm.formModel.targetTypeCode === 10 ||
                _vm.formModel.targetTypeCode === 12)) ||
              _vm.formModel.targetRangeCode === 12 ||
              _vm.formModel.targetRangeCode === 14
          )?_c('el-form-item',{attrs:{"label":_vm.$t('lang.gles.strategy.factoryPositionLimit')}},[_c('el-radio-group',{model:{value:(_vm.formLimit.factoryPosLimit),callback:function ($$v) {_vm.$set(_vm.formLimit, "factoryPosLimit", $$v)},expression:"formLimit.factoryPosLimit"}},[(
                _vm.formModel.targetRangeCode === 12 ||
                  _vm.formModel.targetRangeCode === 13 ||
                  ((_vm.formModel.targetRangeCode === 10 ||
                    _vm.formModel.targetRangeCode === 11) &&
                    _vm.formModel.targetTypeCode === 10)
              )?_c('el-radio',{attrs:{"label":10}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.strategy.emptyFactoryPosition'))+" ")]):_vm._e(),(
                _vm.formModel.targetRangeCode === 14 ||
                  ((_vm.formModel.targetRangeCode === 10 ||
                    _vm.formModel.targetRangeCode === 11) &&
                    _vm.formModel.targetTypeCode === 12)
              )?_c('el-radio',{attrs:{"label":11}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.strategy.notEmptyFactoryPosition'))+" ")]):_vm._e()],1)],1):_vm._e(),(_vm.formModel.targetRangeCode === 13 ||
            ((_vm.formModel.targetRangeCode === 10 ||
              _vm.formModel.targetRangeCode === 11) &&
              _vm.formModel.targetTypeCode === 11)
          )?_c('el-form-item',{attrs:{"label":_vm.$t('lang.gles.strategy.fixContainerLimit')}},[_c('el-radio-group',{model:{value:(_vm.formLimit.fixedContainerLimit),callback:function ($$v) {_vm.$set(_vm.formLimit, "fixedContainerLimit", $$v)},expression:"formLimit.fixedContainerLimit"}},[_c('el-radio',{attrs:{"label":10}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.strategy.emptyFixContainer'))+" ")])],1)],1):_vm._e(),(_vm.formModel.targetRangeCode === 13 ||
            ((_vm.formModel.targetRangeCode === 10 ||
              _vm.formModel.targetRangeCode === 11) &&
              _vm.formModel.targetTypeCode === 11)
          )?_c('el-form-item',{attrs:{"label":_vm.$t('lang.gles.strategy.fixGoodsPositionLimit')}},[_c('el-radio-group',{model:{value:(_vm.formLimit.fixGoodsPosLimit),callback:function ($$v) {_vm.$set(_vm.formLimit, "fixGoodsPosLimit", $$v)},expression:"formLimit.fixGoodsPosLimit"}},[_c('el-radio',{attrs:{"label":10}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.strategy.emptyGoodsPosition'))+" ")])],1)],1):_vm._e(),(_vm.formModel.targetRangeCode === 14 ||
            ((_vm.formModel.targetRangeCode === 10 ||
              _vm.formModel.targetRangeCode === 11) &&
              _vm.formModel.targetTypeCode === 12)
          )?_c('el-form-item',{attrs:{"label":_vm.$t('lang.gles.strategy.movedContainerLimit')}},[_c('el-radio-group',{model:{value:(_vm.formLimit.movedContainerLimit),callback:function ($$v) {_vm.$set(_vm.formLimit, "movedContainerLimit", $$v)},expression:"formLimit.movedContainerLimit"}},[_c('el-radio',{attrs:{"label":10}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.strategy.emptyMovedContainer'))+" ")]),_c('el-radio',{attrs:{"label":11}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.strategy.notEmptyMovedContainer'))+" ")]),_c('el-radio',{attrs:{"label":12}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.strategy.notEmptyMovedContainerAndMixLimit'))+" ")])],1)],1):_vm._e(),(_vm.formModel.targetRangeCode === 14 ||
            ((_vm.formModel.targetRangeCode === 10 ||
              _vm.formModel.targetRangeCode === 11) &&
              _vm.formModel.targetTypeCode === 12)
          )?_c('el-form-item',{attrs:{"label":_vm.$t('lang.gles.strategy.movedGoodsPositionLimit')}},[_c('el-radio-group',{model:{value:(_vm.formLimit.movedContainerGoodsPosLimit),callback:function ($$v) {_vm.$set(_vm.formLimit, "movedContainerGoodsPosLimit", $$v)},expression:"formLimit.movedContainerGoodsPosLimit"}},[_c('el-radio',{attrs:{"label":10}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.strategy.emptyMovedGoodsPosition'))+" ")]),_c('el-radio',{attrs:{"label":11,"disabled":_vm.formLimit.movedContainerLimit === 12 || _vm.formLimit.movedContainerLimit === 10}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.strategy.notEmptyMovedGoodsPositionLimit'))+" ")]),_c('el-radio',{attrs:{"label":12,"disabled":_vm.formLimit.movedContainerLimit === 10}},[_vm._v(" "+_vm._s(_vm.$t( 'lang.gles.strategy.notEmptyMovedGoodsPositionLimitAndMixLimit' ))+" ")])],1)],1):_vm._e(),(_vm.formModel.targetRangeCode === 14 ||
            ((_vm.formModel.targetRangeCode === 10 ||
              _vm.formModel.targetRangeCode === 11) &&
              _vm.formModel.targetTypeCode === 12)
          )?_c('el-form-item',{attrs:{"label":_vm.$t('lang.gles.strategy.turnoverContainerLimit')}},[_c('el-radio-group',{model:{value:(_vm.formLimit.turnoverContainerLimit),callback:function ($$v) {_vm.$set(_vm.formLimit, "turnoverContainerLimit", $$v)},expression:"formLimit.turnoverContainerLimit"}},[_c('el-radio',{attrs:{"label":10}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.strategy.noLimit'))+" ")]),_c('el-radio',{attrs:{"label":11,"disabled":_vm.formLimit.movedContainerLimit === 10 || _vm.formLimit.movedContainerGoodsPosLimit === 10}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.strategy.equalContainerType'))+" ")])],1)],1):_vm._e(),(_vm.formModel.targetRangeCode === 14 ||
            ((_vm.formModel.targetRangeCode === 10 ||
              _vm.formModel.targetRangeCode === 11) &&
              _vm.formModel.targetTypeCode === 12)
          )?_c('el-form-item',{attrs:{"label":_vm.$t('lang.gles.strategy.materialLimit')}},[_c('el-radio-group',{model:{value:(_vm.formLimit.materialLimit),callback:function ($$v) {_vm.$set(_vm.formLimit, "materialLimit", $$v)},expression:"formLimit.materialLimit"}},[_c('el-radio',{attrs:{"label":10}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.strategy.noLimit'))+" ")]),_c('el-radio',{attrs:{"label":11,"disabled":_vm.formLimit.movedContainerLimit === 10 || _vm.formLimit.movedContainerGoodsPosLimit === 10}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.strategy.equalMaterialCode'))+" ")]),_c('el-radio',{attrs:{"label":12,"disabled":_vm.formLimit.movedContainerLimit === 10 || _vm.formLimit.movedContainerGoodsPosLimit === 10}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.strategy.equalMaterialBatchProp'))+" ")])],1)],1):_vm._e(),(_vm.formModel.targetRangeCode === 12 ||
            ((_vm.formModel.targetRangeCode === 10 ||
              _vm.formModel.targetRangeCode === 11) &&
              _vm.formModel.targetTypeCode === 10)
          )?_c('el-form-item',{attrs:{"label":_vm.$t('lang.gles.strategy.quantityLimit')}},[_c('el-checkbox-group',{model:{value:(_vm.formLimit.numLimit),callback:function ($$v) {_vm.$set(_vm.formLimit, "numLimit", $$v)},expression:"formLimit.numLimit"}},[_c('el-checkbox',{attrs:{"label":'10'}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.strategy.factoryPositionPutContainerMaxLimit'))+" ")]),_c('el-checkbox',{attrs:{"label":'11'}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.strategy.factoryPositionPutMaterialMaxLimit'))+" ")])],1)],1):_vm._e(),(_vm.formModel.targetRangeCode === 14 ||
            ((_vm.formModel.targetRangeCode === 10 ||
              _vm.formModel.targetRangeCode === 11) &&
              _vm.formModel.targetTypeCode === 12)
          )?_c('el-form-item',{attrs:{"label":_vm.$t('lang.gles.strategy.quantityLimit')}},[_c('el-checkbox-group',{model:{value:(_vm.formLimit.numLimit),callback:function ($$v) {_vm.$set(_vm.formLimit, "numLimit", $$v)},expression:"formLimit.numLimit"}},[_c('el-checkbox',{attrs:{"label":'30'}},[_vm._v(" "+_vm._s(_vm.$t( 'lang.gles.strategy.movedGoodsPositionPutContainerMaxLimit' ))+" ")]),_c('el-checkbox',{attrs:{"label":'31'}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.strategy.movedGoodsPositionPutMaterialMaxLimit'))+" ")])],1)],1):_vm._e(),(_vm.formModel.targetRangeCode === 13 ||
            ((_vm.formModel.targetRangeCode === 10 ||
              _vm.formModel.targetRangeCode === 11) &&
              _vm.formModel.targetTypeCode === 11)
          )?_c('el-form-item',{attrs:{"label":_vm.$t('lang.gles.strategy.quantityLimit')}},[_c('el-checkbox-group',{model:{value:(_vm.formLimit.numLimit),callback:function ($$v) {_vm.$set(_vm.formLimit, "numLimit", $$v)},expression:"formLimit.numLimit"}},[_c('el-checkbox',{attrs:{"label":'20'}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.strategy.fixGoodsPositionPutContainerMaxLimit'))+" ")]),_c('el-checkbox',{attrs:{"label":'21'}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.strategy.fixGoodsPositionPutMaterialMaxLimit'))+" ")])],1)],1):_vm._e()],1)],1),_c('div',{staticClass:"footer-btns"},[_c('el-button',{on:{"click":_vm.onCancel}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.common.cancel'))+" ")]),_c('el-button',{directives:[{name:"debounce",rawName:"v-debounce",value:(_vm.onSubmit),expression:"onSubmit"}],attrs:{"type":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('lang.gles.common.confirm'))+" ")])],1)]):_vm._e(),_c('add-target-range',{ref:"addTargetRange",on:{"addRange":_vm.updateTargetRangeList}}),_c('add-type-filter',{ref:"addTypeFilter",attrs:{"good-position-num-list":_vm.goodPositionNumList},on:{"addTypeFilter":_vm.updateTypeFilterList,"getGoodsPositionNumList":_vm.getGoodsPositionNumList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }