<template>
  <el-dialog
    :title="$t('lang.gles.strategy.addStrategy')"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
    width="30%"
    @closed="reset"
  >
    <m-form
      ref="myForm"
      :form-data="addStrategyFormData"
      label-position="right"
      :label-width="120"
      :extend-config="extendFormConfig"
    />
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">{{ $t('lang.gles.common.cancel') }}</el-button>
      <el-button
        type="primary"
        @click="strategySubmit"
      >{{ $t('lang.gles.common.confirm') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import MForm from '@/libs_sz/components/MForm/MForm.vue'
import { getStrategyFormData } from '../data'
export default {
  name: 'AddStrategy',
  components: { MForm },
  props: {
  },
  data() {
    return {
      visible: false,
      submitLoading: false,
      // form表单相关的
      extendFormConfig: {
        isNeedBtn: false
      },
      options: {}
    }
  },
  computed: {
    addStrategyFormData() {
      return getStrategyFormData(this, this.options)
    }
  },
  mounted() {

  },
  methods: {
    init(options) {
      this.visible = true
      this.options = options
    },
    reset() {
      this.$refs.myForm.resetForm()
    },
    // 表单提交
    strategySubmit() {
      this.$refs.myForm.getValidateFormModel().then(async(model) => {
        model.remark = this.options.strategy_dict.find(item => item.value === model.strategyName)?.remark
        this.$emit('addStrategy', model)
        this.visible = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-dialog__footer{
    text-align: center;
}
</style>
