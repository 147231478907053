<template>
  <div class="basic-data-add">
    <el-card class="mb10">
      <div class="form_title b mb15">
        {{ $t('lang.gles.common.basicInfo') }}
      </div>
      <m-form
        ref="myForm"
        :form-data="basicFormData"
        label-position="right"
        :label-width="120"
        :extend-config="extendFormConfig"
      >
        <template #inputCodeTipIcon>
          <el-tooltip
            popper-class="workshop_code_tip-icon"
            effect="dark"
            :content="$t(autoGeneCodeTip)"
            placement="right"
          >
            <i class="el-icon-tip el-icon-question tip" />
          </el-tooltip>
        </template>
      </m-form>
      <el-button type="primary" class="ml20" @click="onCancel">
        {{ $t('lang.gles.common.back') }}
      </el-button>
    </el-card>

    <el-card v-if="isShowAll" class="add-card">
      <div class="form_title b mb15">
        {{ $t('lang.gles.strategy.strategyDetail') }}
      </div>
      <!-- 目标范围 -->
      <div v-if="showTargetRange" class="targetRange">
        <el-divider content-position="left">
          {{ $t('lang.gles.strategy.targetRange') }}
        </el-divider>
        <div
          v-if="formModel.targetRangeCode === 11 && !!formModel.targetTypeCode"
          class="ml30"
        >
          <el-form ref="form1" :model="ruleForm" label-width="80px">
            <el-form-item :label="$t('lang.gles.strategy.distributeRule')">
              <el-select
                v-model="ruleForm.distributeRule"
                :placeholder="$t('lang.gles.common.pleaseSelect')"
                clearable
              >
                <el-option
                  :label="$t('lang.gles.strategy.stockInSequential')"
                  :value="10"
                />
              </el-select>
              <el-tooltip
                popper-class="workshop_code_tip-icon"
                effect="dark"
                :content="
                  formModel.targetTypeCode === 10
                    ? $t('lang.gles.strategy.needSetStockInSeqForFactoryPosition')
                    : $t('lang.gles.strategy.needSetStockInSeqForGoodsPosition')
                "
                placement="right"
              >
                <i class="el-icon-tip el-icon-question ml5" />
              </el-tooltip>
            </el-form-item>
          </el-form>
        </div>
        <div v-else class="btns ml30">
          <el-button
            type="text"
            icon="el-icon-circle-plus"
            @click="addTargetRange"
          >
            {{ $t('lang.gles.strategy.add') }}
          </el-button>
          <el-button
            type="text"
            style="color: #f56c6c"
            icon="el-icon-delete-solid"
            @click="batchDelRange"
          >
            {{ $t('lang.gles.common.batchDelete') }}
          </el-button>
          <!-- <m-edit-table
            ref="targetRangeEditTable"
            border
            :table-data="targetRangeTableData"
            :extend-config="extendTargetTableConfig"
            :table-item="targetRangeTableItem"
            :form-data="targetRangeTableFormData"
            :page-data="null"
            @delRangeRow="delRangeRow"
            @mEditTableEditRow="editTargetRow"
            @upRow="upRow"
            @downRow="downRow"
            @selection-change="(val) => (selectTargetRange = val)"
          /> -->
          <m-table
            ref="targetRangeEditTable"
            border
            :table-data="targetRangeTableData"
            :extend-config="extendTargetTableConfig"
            :table-item="targetRangeTableItem"
            :page-data="null"
            @delRangeRow="delRangeRow"
            @mEditTableEditRow="editTargetRow"
            @upRow="upRow"
            @downRow="downRow"
            @selection-change="(val) => (selectTargetRange = val)"
          />
        </div>
      </div>
      <!-- 容器类型过滤 -->
      <div
        v-if="formModel.targetRangeCode === 13 ||
          formModel.targetRangeCode === 14 ||
          ((formModel.targetRangeCode === 10 ||
            formModel.targetRangeCode === 11) &&
            (formModel.targetTypeCode === 11 ||
              formModel.targetTypeCode === 12))
        "
        class="targetRange"
      >
        <el-divider content-position="left">
          {{ $t('lang.gles.strategy.shelf.containerTypeFilter') }}
        </el-divider>
        <div class="btns ml30">
          <el-button
            type="text"
            icon="el-icon-circle-plus"
            @click="addTypeFilter"
          >
            {{ $t('lang.gles.strategy.add') }}
          </el-button>
          <el-button
            type="text"
            style="color: #f56c6c"
            icon="el-icon-delete-solid"
            @click="batchDelTypeFilter"
          >
            {{ $t('lang.gles.common.batchDelete') }}
          </el-button>

          <!-- 1111 -->
          <edit-table
            ref="containerFilterEditTable"
            :table-data.sync="containerFilterTableData"
            :table-item="containerFilterTableItem"
            :extend-config="extendTableConfig"
            @editRowItem="editRow"
            @removeRowItem="delTypeFilterRow"
            @selection-change="(val) => (selectTypeFilter = val)"
          />

          <!-- <m-edit-table
            ref="containerFilterEditTable"
            border
            :table-data="containerFilterTableData"
            :extend-config="extendTableConfig"
            :table-item="containerFilterTableItem"
            :form-data="containerFilterTableFormData"
            :page-data="null"
            @removeRowItem="delTypeFilterRow"
            @mEditTableEditRow="editRow"
            @selection-change="(val) => (selectTypeFilter = val)"
          /> -->
        </div>
      </div>
      <div class="limitCondition">
        <el-divider content-position="left">
          {{ $t('lang.gles.strategy.shelf.limitCondition') }}
        </el-divider>
        <el-form ref="form" :model="formLimit" label-width="120px">
          <el-form-item
            v-if="
              ((formModel.targetRangeCode === 10 ||
                formModel.targetRangeCode === 11) &&
                (formModel.targetTypeCode === 10 ||
                  formModel.targetTypeCode === 12)) ||
                formModel.targetRangeCode === 12 ||
                formModel.targetRangeCode === 14
            "
            :label="$t('lang.gles.strategy.factoryPositionLimit')"
          >
            <el-radio-group v-model="formLimit.factoryPosLimit">
              <el-radio
                v-if="
                  formModel.targetRangeCode === 12 ||
                    formModel.targetRangeCode === 13 ||
                    ((formModel.targetRangeCode === 10 ||
                      formModel.targetRangeCode === 11) &&
                      formModel.targetTypeCode === 10)
                "
                :label="10"
              >
                {{ $t('lang.gles.strategy.emptyFactoryPosition') }}
              </el-radio>
              <el-radio
                v-if="
                  formModel.targetRangeCode === 14 ||
                    ((formModel.targetRangeCode === 10 ||
                      formModel.targetRangeCode === 11) &&
                      formModel.targetTypeCode === 12)
                "
                :label="11"
              >
                {{ $t('lang.gles.strategy.notEmptyFactoryPosition') }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="formModel.targetRangeCode === 13 ||
              ((formModel.targetRangeCode === 10 ||
                formModel.targetRangeCode === 11) &&
                formModel.targetTypeCode === 11)
            "
            :label="$t('lang.gles.strategy.fixContainerLimit')"
          >
            <el-radio-group v-model="formLimit.fixedContainerLimit">
              <el-radio :label="10">
                {{ $t('lang.gles.strategy.emptyFixContainer') }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="formModel.targetRangeCode === 13 ||
              ((formModel.targetRangeCode === 10 ||
                formModel.targetRangeCode === 11) &&
                formModel.targetTypeCode === 11)
            "
            :label="$t('lang.gles.strategy.fixGoodsPositionLimit')"
          >
            <el-radio-group v-model="formLimit.fixGoodsPosLimit">
              <el-radio :label="10">
                {{ $t('lang.gles.strategy.emptyGoodsPosition') }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="formModel.targetRangeCode === 14 ||
              ((formModel.targetRangeCode === 10 ||
                formModel.targetRangeCode === 11) &&
                formModel.targetTypeCode === 12)
            "
            :label="$t('lang.gles.strategy.movedContainerLimit')"
          >
            <el-radio-group v-model="formLimit.movedContainerLimit">
              <el-radio :label="10">
                {{ $t('lang.gles.strategy.emptyMovedContainer') }}
              </el-radio>
              <el-radio :label="11">
                {{ $t('lang.gles.strategy.notEmptyMovedContainer') }}
              </el-radio>
              <el-radio :label="12">
                {{ $t('lang.gles.strategy.notEmptyMovedContainerAndMixLimit') }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="formModel.targetRangeCode === 14 ||
              ((formModel.targetRangeCode === 10 ||
                formModel.targetRangeCode === 11) &&
                formModel.targetTypeCode === 12)
            "
            :label="$t('lang.gles.strategy.movedGoodsPositionLimit')"
          >
            <el-radio-group v-model="formLimit.movedContainerGoodsPosLimit">
              <el-radio :label="10">
                {{ $t('lang.gles.strategy.emptyMovedGoodsPosition') }}
              </el-radio>
              <el-radio
                :label="11"
                :disabled="formLimit.movedContainerLimit === 12 || formLimit.movedContainerLimit === 10"
              >
                {{ $t('lang.gles.strategy.notEmptyMovedGoodsPositionLimit') }}
              </el-radio>
              <el-radio
                :label="12"
                :disabled="formLimit.movedContainerLimit === 10"
              >
                {{
                  $t(
                    'lang.gles.strategy.notEmptyMovedGoodsPositionLimitAndMixLimit'
                  )
                }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="formModel.targetRangeCode === 14 ||
              ((formModel.targetRangeCode === 10 ||
                formModel.targetRangeCode === 11) &&
                formModel.targetTypeCode === 12)
            "
            :label="$t('lang.gles.strategy.turnoverContainerLimit')"
          >
            <el-radio-group v-model="formLimit.turnoverContainerLimit">
              <el-radio :label="10">
                {{ $t('lang.gles.strategy.noLimit') }}
              </el-radio>
              <el-radio
                :label="11"
                :disabled="formLimit.movedContainerLimit === 10 || formLimit.movedContainerGoodsPosLimit === 10"
              >
                {{ $t('lang.gles.strategy.equalContainerType') }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="formModel.targetRangeCode === 14 ||
              ((formModel.targetRangeCode === 10 ||
                formModel.targetRangeCode === 11) &&
                formModel.targetTypeCode === 12)
            "
            :label="$t('lang.gles.strategy.materialLimit')"
          >
            <el-radio-group v-model="formLimit.materialLimit">
              <el-radio :label="10">
                {{ $t('lang.gles.strategy.noLimit') }}
              </el-radio>
              <el-radio
                :label="11"
                :disabled="formLimit.movedContainerLimit === 10 || formLimit.movedContainerGoodsPosLimit === 10"
              >
                {{ $t('lang.gles.strategy.equalMaterialCode') }}
              </el-radio>
              <el-radio
                :label="12"
                :disabled="formLimit.movedContainerLimit === 10 || formLimit.movedContainerGoodsPosLimit === 10"
              >
                {{ $t('lang.gles.strategy.equalMaterialBatchProp') }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="formModel.targetRangeCode === 12 ||
              ((formModel.targetRangeCode === 10 ||
                formModel.targetRangeCode === 11) &&
                formModel.targetTypeCode === 10)
            "
            :label="$t('lang.gles.strategy.quantityLimit')"
          >
            <el-checkbox-group v-model="formLimit.numLimit">
              <el-checkbox :label="'10'">
                {{
                  $t('lang.gles.strategy.factoryPositionPutContainerMaxLimit')
                }}
              </el-checkbox>
              <el-checkbox :label="'11'">
                {{
                  $t('lang.gles.strategy.factoryPositionPutMaterialMaxLimit')
                }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item
            v-if="formModel.targetRangeCode === 14 ||
              ((formModel.targetRangeCode === 10 ||
                formModel.targetRangeCode === 11) &&
                formModel.targetTypeCode === 12)
            "
            :label="$t('lang.gles.strategy.quantityLimit')"
          >
            <el-checkbox-group v-model="formLimit.numLimit">
              <el-checkbox :label="'30'">
                {{
                  $t(
                    'lang.gles.strategy.movedGoodsPositionPutContainerMaxLimit'
                  )
                }}
              </el-checkbox>
              <el-checkbox :label="'31'">
                {{
                  $t('lang.gles.strategy.movedGoodsPositionPutMaterialMaxLimit')
                }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item
            v-if="formModel.targetRangeCode === 13 ||
              ((formModel.targetRangeCode === 10 ||
                formModel.targetRangeCode === 11) &&
                formModel.targetTypeCode === 11)
            "
            :label="$t('lang.gles.strategy.quantityLimit')"
          >
            <el-checkbox-group v-model="formLimit.numLimit">
              <el-checkbox :label="'20'">
                {{
                  $t('lang.gles.strategy.fixGoodsPositionPutContainerMaxLimit')
                }}
              </el-checkbox>
              <el-checkbox :label="'21'">
                {{
                  $t('lang.gles.strategy.fixGoodsPositionPutMaterialMaxLimit')
                }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
      <div class="footer-btns">
        <el-button @click="onCancel">
          {{ $t('lang.gles.common.cancel') }}
        </el-button>
        <el-button v-debounce="onSubmit" type="primary">
          {{ $t('lang.gles.common.confirm') }}
        </el-button>
      </div>
    </el-card>
    <!-- 新增目标范围弹窗 -->
    <add-target-range ref="addTargetRange" @addRange="updateTargetRangeList" />
    <!-- 新增容器过滤弹窗 -->
    <add-type-filter
      ref="addTypeFilter"
      :good-position-num-list="goodPositionNumList"
      @addTypeFilter="updateTypeFilterList"
      @getGoodsPositionNumList="getGoodsPositionNumList"
    />
  </div>
</template>
<script>
import MForm from '@/libs_sz/components/MForm/MForm'
import addMixins from '@/mixins/addMixins'
import AddTargetRange from './addTagetRange.vue'
import EditTable from '@/components/tableForm/editBase'
import {
  getEditBaseFormData,
  getTargetTableData,
  editTargetFormData,
  getFilterTableData,
  editFilterFormData
} from '../data'
import { mapActions, mapState } from 'vuex'
import AddTypeFilter from './addTypeFilter.vue'

export default {
  name: 'AddShelfStrategy',
  components: {
    MForm,
    EditTable,
    AddTargetRange,
    AddTypeFilter
  },
  mixins: [addMixins],
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => {}
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      targetRangeTableData: [],
      containerFilterTableData: [],
      // form表单相关的
      extendFormConfig: {
        isNeedBtn: false
      },
      extendTargetTableConfig: {
        sortNum: true,
        checkBox: true,
        executeSort: true,
        operateWidth: '180px',
        operate: [
          {
            event: 'upRow',
            label: this.$t('lang.gles.strategy.moveUp')
          },
          {
            event: 'downRow',
            label: this.$t('lang.gles.strategy.moveDown')
          },
          {
            event: 'mEditTableEditRow',
            label: this.$t('lang.gles.common.edit')
          },
          {
            event: 'delRangeRow',
            label: this.$t('lang.gles.common.delete'),
            confirm: true,
            confirmMessage: this.$t('lang.gles.common.deleteConfirmMsg0'),
            customClass: 'danger'
          }
        ]
      },
      extendTableConfig: {
        sortNum: true,
        checkBox: true
      },
      tableExtendConfig: {
        sortNum: true
      },
      selectTypeFilter: [],
      selectTargetRange: [],
      formLimit: {
        numLimit: [],
        movedContainerLimit: 10,
        movedContainerGoodsPosLimit: 10,
        turnoverContainerLimit: 10,
        materialLimit: 10
      },
      ruleForm: {
        distributeRule: ''
      },
      formModel: {},
      editTargetRangeData: {},
      fixGoodsPositionList: [],
      containerTypeList: [],
      containerTypeCodeList: [],
      cacheContainerTypeList: [],
      cacheContainerTypeCodeList: [],
      goodPositionNumList: [],
      dataSave: {}
    }
  },
  computed: {
    ...mapState('strategyManage', ['distributeRuleList']),
    ...mapState('base', [
      'factoryList',
      'workshopList',
      'warehouseList',
      'warehouseAreaList',
      'factoryPositionList'
    ]),
    basicFormData() {
      const row = this.rowDetail || {}
      return getEditBaseFormData(this, { ...row }, { ...this.options })
    },
    targetRangeTableItem() {
      return getTargetTableData(this)
    },
    targetRangeTableFormData() {
      return editTargetFormData(this, { ...this.editTargetRangeData })
    },
    containerFilterTableItem() {
      return getFilterTableData(this)
    },
    containerFilterTableFormData() {
      return editFilterFormData(this, this.options)
    },
    // card 展示
    isShowAll() {
      if (
        (this.formModel.targetRangeCode === 10 &&
          !this.formModel.targetTypeCode) ||
        (this.formModel.targetRangeCode === 11 &&
          !this.formModel.targetTypeCode) ||
        !this.formModel.targetRangeCode
      ) {
        return false
      }
      return true
    },
    // 目标范围的isShow
    showTargetRange() {
      return !!this.formModel.targetRangeCode
    }
  },
  watch: {
    formModel: {
      handler(formModel) {
        this.targetRangeTableData = []
        this.containerFilterTableData = []
        this.ruleForm = {
          distributeRule: ''
        }
        this.formLimit = {
          numLimit: [],
          movedContainerLimit: 10,
          movedContainerGoodsPosLimit: 10,
          turnoverContainerLimit: 10,
          materialLimit: 10
        }
        if (
          formModel.targetRangeCode === 12 ||
          formModel.targetRangeCode === 13 ||
          ((formModel.targetRangeCode === 10 ||
            formModel.targetRangeCode === 11) &&
            formModel.targetTypeCode === 10)
        ) {
          this.formLimit.factoryPosLimit = 10
        }
        if (
          formModel.targetRangeCode === 14 ||
          ((formModel.targetRangeCode === 10 ||
            formModel.targetRangeCode === 11) &&
            formModel.targetTypeCode === 12)
        ) {
          this.formLimit.factoryPosLimit = 11
        }
        if (
          formModel.targetRangeCode === 13 ||
          ((formModel.targetRangeCode === 10 ||
            formModel.targetRangeCode === 11) &&
            formModel.targetTypeCode === 11)
        ) {
          this.formLimit.fixedContainerLimit = 10
        }
        if (
          formModel.targetRangeCode === 13 ||
          ((formModel.targetRangeCode === 10 ||
            formModel.targetRangeCode === 11) &&
            formModel.targetTypeCode === 11)
        ) {
          this.formLimit.fixGoodsPosLimit = 10
        }
      },
      deep: true
    },
    formLimit: {
      handler(limitCondition) {
        if (
          limitCondition.movedContainerLimit === 12 &&
          limitCondition.movedContainerGoodsPosLimit === 11
        ) {
          this.formLimit.movedContainerGoodsPosLimit = 10
        }
        if (limitCondition.movedContainerLimit === 10) {
          this.formLimit.movedContainerGoodsPosLimit = 10
          this.formLimit.turnoverContainerLimit = 10
          this.formLimit.materialLimit = 10
        }
        if (limitCondition.movedContainerGoodsPosLimit === 10) {
          this.formLimit.turnoverContainerLimit = 10
          this.formLimit.materialLimit = 10
        }
      },
      deep: true
    }
  },
  created() {
    this.initSelectList()
    if (this.mode === 'edit' || this.mode === 'copy') {
      this.getShelfStrategyDetail()
      this.getContainerTypeList(this.rowDetail.targetTypeCode)
      this.dataSave = this._.cloneDeep(this.rowDetail)
    }
  },
  methods: {
    ...mapActions('strategyManage', ['getDistributeRuleList']),
    initSelectList() {
      this.$store.dispatch('base/getFactoryList')
      this.$store.dispatch('base/getWorkshopList')
      this.$store.dispatch('base/getWarehouseList')
      this.$store.dispatch('base/getWarehouseAreaList')
      this.$store.dispatch('base/getFactoryPositionList')
      this.getDistributeRuleList()
      this.getFixGoodsPositionList()
      // this.getGoodsPositionNumList()
    },
    // 根据目标范围不同显示不同目标类型
    onTargetRangeFormChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      if (val === 12) changeFormValue({ targetTypeCode: 10 })
      if (val === 13) {
        changeFormValue({ targetTypeCode: 11 })
        this.getContainerTypeList(11)
      }
      if (val === 14) {
        changeFormValue({ targetTypeCode: 12 })
        this.getContainerTypeList(12)
      }
      this.$emit('update:rowDetail', this._.cloneDeep(formModel))
    },
    onTargetTypeFormChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      this.$emit('update:rowDetail', this._.cloneDeep(formModel))
      this.getContainerTypeList(val)
    },
    /**
     * 获取固定货位列表
     */
    async getFixGoodsPositionList(params) {
      const { data, code } = await this.$httpService(
        this.$apiStore.base('getFixGoodsPositionByWarehouseAreaId')
      )
      if (code) return
      this.fixGoodsPositionList = data.map((item) => {
        return { label: item.code, value: item.id }
      })
    },
    // 获取容器类型数据
    async getContainerTypeList(targetTypeCode) {
      const { data, code } = await this.$httpService(
        this.$apiStore.strategyManage('getContainerTypeAndCode'),
        { targetTypeCode }
      )
      if (code) return
      this.containerTypeList = data.map((item) => {
        return {
          label: item.containerTypeName,
          value: String(item.containerTypeId)
        }
      })
      this.containerTypeCodeList = data.map((item) => {
        return { label: item.containerTypeCode, value: item.containerTypeId }
      })
      this.cacheContainerTypeList = data.map((item) => {
        return {
          label: item.containerTypeName,
          value: String(item.containerTypeId)
        }
      })
      this.cacheContainerTypeCodeList = data.map((item) => {
        return { label: item.containerTypeCode, value: item.containerTypeId }
      })
    },
    // 查询货位编码
    async getGoodsPositionNumList(id) {
      if (!id) {
        this.goodPositionNumList = []
        return
      }

      const { data, code } = await this.$httpService(
        this.$apiStore.strategyManage('getGoodsPositionNum'),
        { id }
      )
      if (code) return
      if (data.goodPositionNumList) {
        this.goodPositionNumList = data.goodPositionNumList.map((item) => {
          return { label: String(item), value: String(item) }
        })
      }
    },
    // edit或copy 下获取详情
    async getShelfStrategyDetail() {
      const { code, data } = await this.$httpService(
        this.$apiStore.strategyManage('detailShelf'),
        { strategyId: this.rowDetail.id }
      )
      if (code) return
      // 回显
      // console.log(data.containerRangeVoList, 'data++++++++')
      this.targetRangeTableData = data.targetRangeVoList
      if (data.targetRangeCode === 11) {
        this.ruleForm.distributeRule =
          data.targetRangeVoList[0]?.distributeRule || ''
      }
      this.containerFilterTableData = data.containerRangeVoList.map((item) => {
        return {
          ...item,
          goodsPositionNum: item.goodsPositionNum
            ? item.goodsPositionNum.split(',')
            : []
        }
      })
      this.formLimit = {
        ...data.limitConditionVo,
        numLimit: data.limitConditionVo.numLimit
          ? data.limitConditionVo.numLimit.split(',')
          : []
      }
      // console.log(this.containerFilterTableData, '0----')
    },
    // 添加目标范围
    addTargetRange() {
      const options = {
        distributeRuleList: this.distributeRuleList
      }
      this.$refs.addTargetRange.init({ ...this.formModel, options }, 'add')
    },
    // 删除
    delRangeRow(row) {
      this.targetRangeTableData.splice(row.$index, 1)
      // this.$refs.targetRangeEditTable.removeRow(row)
    },
    editTargetRow(row) {
      const options = {
        distributeRuleList: this.distributeRuleList
      }
      this.$refs.addTargetRange.init(
        { ...this.formModel, options },
        'edit',
        row
      )
    },
    // // 上移，下移
    upRow({ $index, row }) {
      if ($index > 0) {
        const upDate = this.targetRangeTableData[$index - 1]
        this.targetRangeTableData.splice($index - 1, 1)
        this.targetRangeTableData.splice($index, 0, upDate)
      } else {
        this.$message({
          type: 'warning',
          message: this.$t('lang.gles.strategy.firstRecordNotBeMoveUp')
        })
      }
    },
    downRow({ $index, row }) {
      if ($index + 1 === this.targetRangeTableData.length) {
        this.$message({
          type: 'warning',
          message: this.$t('lang.gles.strategy.lastRecordNotBeMoveUp')
        })
      } else {
        const downDate = this.targetRangeTableData[$index + 1]
        this.targetRangeTableData.splice($index + 1, 1)
        this.targetRangeTableData.splice($index, 0, downDate)
      }
    },
    updateTargetRangeList(val, index) {
      if (typeof index === 'undefined') {
        val.forEach((item) => {
          this.targetRangeTableData.push(item)
        })
      } else {
        this.targetRangeTableData.splice(index, 1, ...val)
      }
    },
    batchDelRange() {
      const selectData = this.selectTargetRange
      if (selectData.length < 1) {
        this.$message({
          type: 'error',
          message: this.$t('lang.gles.strategy.atLeastOneRecordCouldBeDelete')
        })
        return
      }
      selectData.forEach((item) => {
        delete item.isEdit
        delete item.isNew
        const findIndex = this.targetRangeTableData.findIndex((c) => {
          return JSON.stringify(c) === JSON.stringify(item)
        })
        // 清楚当前的tableData
        this.targetRangeTableData.splice(findIndex, 1)
      })
    },
    // 添加容器过滤类型
    addTypeFilter() {
      // 添加新的容器过滤类型之前过滤掉已添加的
      const containerTypeIds = this.containerFilterTableData.map(
        (item) => item.containerTypeId
      )
      containerTypeIds.length > 0 &&
        containerTypeIds.forEach((item) => {
          this.containerTypeList = this.containerTypeList.filter(
            (cv) => String(cv.value) !== String(item)
          )
          this.containerTypeCodeList = this.containerTypeCodeList.filter(
            (cv) => String(cv.value) !== String(item)
          )
        })
      const options = {
        containerTypeCodeList: this.containerTypeCodeList,
        containerTypeList: this.containerTypeList
      }
      this.$refs.addTypeFilter.init({ ...this.formModel }, options)
    },
    updateTypeFilterList(val) {
      this.containerFilterTableData.push({ ...val })
    },
    // 删除子容器类型过滤
    delTypeFilterRow(row) {
      // this.$refs.containerFilterEditTable.removeRowItem(row)
      this.containerTypeList = this.cacheContainerTypeList
      this.containerTypeCodeList = this.cacheContainerTypeCodeList
    },
    // 批量删除容器类型过滤
    batchDelTypeFilter() {
      const selectData = this.selectTypeFilter
      // console.log(this.selectTypeFilter,"0900000")
      if (selectData.length < 1) {
        this.$message({
          type: 'error',
          message: this.$t('lang.gles.strategy.atLeastOneRecordCouldBeDelete')
        })
        return
      }
      selectData.forEach((item) => {
        delete item.isEdit
        delete item.isNew
        const findIndex = this.containerFilterTableData.findIndex((c) => {
          return JSON.stringify(c) === JSON.stringify(item)
        })
        // 清楚当前的tableData
        this.containerFilterTableData.splice(findIndex, 1)
      })
      this.containerTypeList = this.cacheContainerTypeList
      this.containerTypeCodeList = this.cacheContainerTypeCodeList
    },

    editRow(row) {
      // 更新货位序号编码
      if (this.$refs.myForm.formModel.targetTypeCode === 12) {
        this.getGoodsPositionNumList(row.row.containerTypeId)
      }
    },
    onSubmit() {
      // console.log(this.rowDetail, 'mode', this.dataSave)
      this.$refs.myForm.getValidateFormModel().then(async(model) => {
        const param = { ...this.rowDetail, ...model }
        const targetRangeTableData =
          this.targetRangeTableData.length > 0
            ? this.targetRangeTableData.map((item, index) => {
              if (item.goodsPositionId && Array.isArray(item.goodsPositionId)) {
                item.goodsPositionId = item.goodsPositionId.join(',')
              }
              if (item.factoryPositionId && Array.isArray(item.factoryPositionId)) {
                item.factoryPositionId = item.factoryPositionId.join(',')
              }
              return { ...item, executeSort: index + 1 }
            })
            : []
        // 参数处理
        // console.log(targetRangeTableData,"-======")
        const containerFilterTableData = this.$refs.containerFilterEditTable
          ? this.$refs.containerFilterEditTable.getTableData().map((item) => {
            // console.log(item.goodsPositionNum, 'goodsPositionNum')
            return {
              containerTypeId: item.containerTypeId,
              containerTypeName: item.containerTypeName,
              goodsPositionNum: Array.isArray(item.goodsPositionNum)
                ? item.goodsPositionNum.join(',')
                : item.goodsPositionNum
            }
          })
          : []
        const limitParams = this._.cloneDeep(this.formLimit)
        limitParams.numLimit = Array.isArray(limitParams.numLimit)
          ? limitParams.numLimit.join(',')
          : limitParams.numLimit
        // 处理不同情况的限制条件参数
        if (param.targetTypeCode === 10 || param.targetTypeCode === 11) {
          delete limitParams.materialLimit
          delete limitParams.movedContainerGoodsPosLimit
          delete limitParams.movedContainerLimit
          delete limitParams.turnoverContainerLimit
        }
        if (param.targetRangeCode === 13) delete limitParams.factoryPosLimit
        // console.log(limitParams, 'limit33333', param.targetTypeCode)
        const params = {
          ...param,
          targetRangeDtoList: this.ruleForm.distributeRule
            ? [{ distributeRule: this.ruleForm.distributeRule }]
            : targetRangeTableData,
          containerTypeFilterDtoList: containerFilterTableData,
          limitConditionDto: limitParams
        }

        if (this.mode === 'edit') {
          params.strategyId = this.dataSave.id
          params.targetRangeDtoList = params.targetRangeDtoList.map((item) => {
            return {
              executeSort: item.executeSort,
              factoryId: item.factoryId,
              workshopId: item.workshopId,
              warehouseId: item.warehouseId,
              warehouseAreaId: item.warehouseAreaId,
              factoryPositionId: item.factoryPositionId,
              goodsPositionId: item.goodsPositionId,
              distributeRule: item.distributeRule
            }
          })
          params.containerTypeFilterDtoList =
            params.containerTypeFilterDtoList.map((item) => {
              return {
                containerTypeId: item.containerTypeId,
                containerTypeName: item.containerTypeName,
                goodsPositionNum: item.goodsPositionNum
              }
            })
          delete params.id
          delete params.limitConditionDto.id
          delete params.limitConditionDto.strategyId
        }
        if (this.mode === 'copy') {
          delete params.id
          delete params.limitConditionDto.id
          delete params.limitConditionDto.strategyId
          params.targetRangeDtoList = params.targetRangeDtoList.map((item) => {
            return {
              executeSort: item.executeSort,
              factoryId: item.factoryId,
              workshopId: item.workshopId,
              warehouseId: item.warehouseId,
              warehouseAreaId: item.warehouseAreaId,
              factoryPositionId: item.factoryPositionId,
              goodsPositionId: item.goodsPositionId,
              distributeRule: item.distributeRule
            }
          })
          params.containerTypeFilterDtoList =
            params.containerTypeFilterDtoList.map((item) => {
              return {
                containerTypeId: item.containerTypeId,
                containerTypeName: item.containerTypeName,
                goodsPositionNum: item.goodsPositionNum
              }
            })
        }
        // console.log(params, 'params', this.targetRangeTableData, this.formLimit)
        this.mode === 'add' ? await this.insert(params) : this.mode === 'edit' ? await this.update(params) : await this.copy(params)
      })
    },
    async insert(params) {
      const { code } = await this.$httpService(
        this.$apiStore.strategyManage('addShelf'),
        params
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.addSuccessfully'))
      this.onCancel()
    },
    async update(params) {
      const { code } = await this.$httpService(
        this.$apiStore.strategyManage('updateShelf'),
        params
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.updateSuccessfully'))
      this.onCancel()
    },
    async copy(params) {
      const { code } = await this.$httpService(
        this.$apiStore.strategyManage('copyShelf'),
        params
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.strategy.copySuccessfully'))
      this.onCancel()
    },
    // 取消
    onCancel() {
      this.$emit('updateCom', {
        currentCom: 'ShelfStrategyList',
        mode: ''
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.form_title {
  border-left: 4px solid #409eff;
  height: 18px;
  line-height: 20px;
  padding-left: 8px;
  font-size: 16px;
}
.tip {
  display: inline-block;
  position: absolute;
  top: 6px;
  right: -24px;
}
.el-card__body {
  display: flex;
  flex-direction: column;
  .footer-btns {
    flex: 0 0 60px;
    left: 201px;
    bottom: 20px;
    z-index: 10;
    width: calc(100vw - 212px);
    height: 60px;
    line-height: 60px;
    margin-top: 10px;
    text-align: center;
  }
}
</style>
