import mapVerify from '@/utils/formValidate'

const filterFormater = (data = [], value) => {
  return data?.find(t => t.value == value)?.label ?? value
}

export const getMoreQueryFormData = (that, options, row) => {
  const moreQueryData = [
    // 策略编码
    {
      name: 'strategyCode',
      span: 12,
      component: 'elInput',
      label: that.$t('lang.gles.strategy.strategyCode')
    },
    // 策略名称
    {
      name: 'strategyName',
      span: 12,
      component: 'elInput',
      label: that.$t('lang.gles.strategy.strategyName'),
      clearable: true
    },
    // 目标范围
    {
      name: 'targetRangeCode',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.targetRange'),
      clearable: true,
      data: options.targetRangeList || []
    },
    // 目标类型
    {
      name: 'targetTypeCode',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.targetType'),
      clearable: true,
      filterable: true,
      data: options.targetTypeList || []
    },
    // 状态
    {
      name: 'status',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.common.statusFlag'),
      clearable: true,
      filterable: true,
      data: that.statusFlagList || []
    }
  ]
  return moreQueryData
}
// 查询列表列表
export const getSearchTableItem = (that, options) => {
  const searchTableItem = [
    // 策略编码
    {
      prop: 'strategyCode',
      label: that.$t('lang.gles.strategy.strategyCode'),
      width: 140,
      sortable: true,
      isShow: true
    },
    // 策略名称
    {
      prop: 'strategyName',
      label: that.$t('lang.gles.strategy.strategyName'),
      isShow: true
    },
    // 目标范围
    {
      prop: 'targetRangeCode',
      label: that.$t('lang.gles.strategy.targetRange'),
      formatter(row, column) {
        return filterFormater(that.targetRangeList, row[column])
      },
      isShow: true
    },
    // 目标类型
    {
      prop: 'targetTypeCode',
      label: that.$t('lang.gles.strategy.targetType'),
      width: 160,
      formatter(row, column) {
        return filterFormater(that.targetTypeList, row[column])
      },
      isShow: true
    },
    // 状态
    {
      prop: 'status',
      label: that.$t('lang.gles.common.statusFlag'),
      slotName: 'enableStatus',
      width: 120,
      isShow: true
    },
    // 备注
    {
      prop: 'remark',
      label: that.$t('lang.gles.strategy.remark'),
      isShow: true
    }
  ]
  searchTableItem.forEach((item) => (item.showOverflowTooltip = true))
  return searchTableItem
}

// 编辑弹框 基本信息
export const getEditBaseFormData = (that, row, options) => {
  that.formModel = {
    targetRangeCode: row?.targetRangeCode,
    targetTypeCode: row?.targetTypeCode
  }
  const editBaseFormData = [
    // 策略编码
    {
      name: 'strategyCode',
      value: row.strategyCode || '',
      span: 8,
      component: 'elInput',
      label: that.$t('lang.gles.strategy.strategyCode'),
      rules: mapVerify(['required', 'inputCode']),
      clearable: true,
      appendSlotName: 'inputCodeTipIcon',
      class: 'tip-icon'
    },
    // 策略名称
    {
      name: 'strategyName',
      value: row.strategyName || '',
      span: 8,
      component: 'elInput',
      label: that.$t('lang.gles.strategy.strategyName'),
      placeholder: that.$t('lang.gles.common.inputLen200'),
      rules: mapVerify(['required', 'inputLen200']),
      clearable: true
    },
    // 备注
    {
      name: 'remark',
      value: row.remark || '',
      span: 8,
      component: 'elInput',
      label: that.$t('lang.gles.strategy.remark'),
      rules: mapVerify(['inputLen500']),
      clearable: true
    },
    // 目标范围
    {
      name: 'targetRangeCode',
      value: row.targetRangeCode || '',
      span: 8,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.targetRange'),
      rules: mapVerify(['required']),
      clearable: true,
      data: options.targetRangeList || [],
      mchange: that.onTargetRangeFormChange
    },
    // 目标类型
    {
      name: 'targetTypeCode',
      value: row.targetTypeCode || '',
      span: 8,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.targetType'),
      rules: mapVerify(['required']),
      clearable: true,
      disabled: [12, 13, 14].includes(that.formModel.targetRangeCode),
      data: options.targetTypeList || [],
      mchange: that.onTargetTypeFormChange
    }
  ]
  return editBaseFormData
}
// 目标范围table
export const getTargetTableData = (that) => {
  const targetTableData = [
    {
      prop: 'factoryId',
      label: that.$t('lang.gles.strategy.factory'),
      formatter(row, column) {
        return filterFormater(that.factoryList, row[column])
      }
    },
    {
      prop: 'workshopId',
      label: that.$t('lang.gles.strategy.workshop'),
      formatter(row, column) {
        return filterFormater(that.workshopList, row[column])
      }
    },
    {
      prop: 'warehouseId',
      label: that.$t('lang.gles.strategy.warehouse'),
      formatter(row, column) {
        return filterFormater(that.warehouseList, row[column])
      }
    },
    {
      prop: 'warehouseAreaId',
      label: that.$t('lang.gles.strategy.warehouseArea'),
      formatter(row, column) {
        return filterFormater(that.warehouseAreaList, row[column])
      }
    }
  ]
  if (
    (that.formModel.targetTypeCode === 11 &&
      that.formModel.targetRangeCode === 10) ||
    that.formModel.targetRangeCode === 13
  ) {
    targetTableData.push({
      prop: 'goodsPositionId',
      label: that.$t('lang.gles.strategy.fixGoodsPosition'),
      formatter(row, column) {
        return filterFormater(that.fixGoodsPositionList, row[column])
      }
    })
  } else {
    targetTableData.push({
      prop: 'factoryPositionId',
      label: that.$t('lang.gles.base.belongingFactoryPosition'),
      formatter(row, column) {
        return filterFormater(that.factoryPositionList, row[column])
      }
    })
  }
  // 分配规则
  targetTableData.push({
    prop: 'distributeRule',
    label: that.$t('lang.gles.strategy.distributeRule'),
    formatter(row, column) {
      console.log('分配规则', that.distributeRuleList, row[column], filterFormater(that.distributeRuleList, row[column]))
      return filterFormater(that.distributeRuleList, row[column], 'distributeRule')
    }
  })
  targetTableData.forEach((item) => (item.showOverflowTooltip = true))
  return targetTableData
}
// 可编辑子table的form表单 ---目标范围(废弃)
export const editTargetFormData = (that, row) => {
  const targetFormData = [
    {
      name: 'factoryId',
      value: '',
      component: 'elSelect',
      data: that.factoryList || [],
      clearable: true
    },
    {
      name: 'workshopId',
      value: '',
      component: 'elSelect',
      data: that.workshopList || [],
      clearable: true
    },
    {
      name: 'warehouseId',
      value: '',
      component: 'elSelect',
      data: that.warehouseList || [],
      clearable: true
    },
    {
      name: 'warehouseAreaId',
      value: '',
      component: 'elSelect',
      data: that.warehouseAreaList || [],
      clearable: true
    }
  ]
  if (
    (that.formModel.targetTypeCode === 11 &&
      that.formModel.targetRangeCode !== 14) ||
    that.formModel.targetRangeCode === 13
  ) {
    targetFormData.push({
      name: 'goodsPositionId',
      value: '',
      component: 'elSelect',
      data: that.fixGoodsPositionList || [],
      clearable: true
    })
  } else {
    targetFormData.push({
      name: 'factoryPositionId',
      value: '',
      component: 'RemoteSelect',
      labelText: 'factoryPositionCode',
      showLabel: 'factoryPositionCode',
      // data: that.factoryPositionList || [],
      clearable: true
    })
  }
  // 分配规则
  targetFormData.push({
    name: 'distributeRule',
    value: '',
    component: 'elSelect',
    data: that.distributeRuleList || [],
    clearable: true
  })
  return targetFormData
}
export const getAddTargetRangeFormData = (that, options, { row }) => {
  const editTargetRangeFormData = [
    // 工厂
    {
      name: 'factoryId',
      value: row?.factoryId || '',
      span: 20,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.factory'),
      rules: mapVerify(['required']),
      clearable: true,
      data: that.factoryList || [],
      mchange: that.handleFactoryChange
    },
    // 车间
    {
      name: 'workshopId',
      value: row?.workshopId || '',
      span: 20,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.workshop'),
      rules: that.$refs.myRangeForm?.formModel.warehouseId
        ? []
        : mapVerify(['required']),
      clearable: true,
      data: that.workshopList || [],
      disabled: !!that.$refs.myRangeForm?.formModel.warehouseId,
      mchange: that.handleWorkshopChange
    },
    // 仓库
    {
      name: 'warehouseId',
      value: row?.warehouseId || '',
      span: 20,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.warehouse'),
      rules: that.$refs.myRangeForm?.formModel.workshopId
        ? []
        : mapVerify(['required']),
      clearable: true,
      data: that.warehouseList || [],
      disabled: !!that.$refs.myRangeForm?.formModel.workshopId,
      mchange: that.handleWarehouseChange
    },
    // 库区
    {
      name: 'warehouseAreaId',
      value: row?.warehouseAreaId || '',
      span: 20,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.warehouseArea'),
      rules: mapVerify(['required']),
      clearable: true,
      data: that.warehouseAreaList || [],
      mchange: that.handleWarehouseAreaChange
    }
  ]
  if (
    (options.targetTypeCode === 11 && options.targetRangeCode === 10) ||
    options.targetRangeCode === 13
  ) {
    editTargetRangeFormData.push({
      name: 'goodsPositionId',
      value: row?.goodsPositionId || [],
      span: 20,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.fixGoodsPosition'),
      clearable: true,
      multiple: true,
      data: that.fixGoodsPositionList || [],
      mchange: that.handleFactoryPositionChange,
      condition(formModel) {
        // 来控制是否展示
        return !formModel.distributeRule
      }
    })
  } else {
    editTargetRangeFormData.push({
      name: 'factoryPositionId',
      value: row?.factoryPositionId || [],
      span: 20,
      component: 'RemoteSelect',
      label: that.$t('lang.gles.base.factoryPosition'),
      clearable: true,
      multiple: true,
      labelText: 'factoryPositionCode',
      showLabel: 'factoryPositionCode',
      // data: that.factoryPositionList || [],
      mchange: that.handleFactoryPositionChange,
      condition(formModel) {
        // 来控制是否展示
        return !formModel.distributeRule
      }
    })
  }
  editTargetRangeFormData.push({
    name: 'distributeRule',
    value: row?.distributeRule || '',
    span: 20,
    component: 'elSelect',
    label: that.$t('lang.gles.strategy.distributeRule'),
    appendSlotName: 'inputCodeTipIcon',
    class: 'tip-icon',
    clearable: true,
    data: options.options?.distributeRuleList || [],
    mchange: that.handleChange,
    condition(formModel) {
      // console.log(formModel,"modeeee")
      // 来控制是否展示
      if (
        (options.targetTypeCode === 11 && options.targetRangeCode !== 14) ||
        options.targetRangeCode === 13
      ) {
        return !formModel.goodsPositionId?.length
      }
      return !formModel.factoryPositionId?.length
    }
  })
  return editTargetRangeFormData
}
export const getContainerTypeFilterFormData = (that, options, row) => {
  const flag =
    (row.targetTypeCode === 12 &&
      (row.targetRangeCode === 10 || row.targetRangeCode === 11)) ||
    row.targetRangeCode === 14
  const containerTypeFilterFormData = [
    // 容器类型编码
    {
      name: 'containerTypeId',
      value: '',
      span: 20,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.containerTypeCode'),
      rules: mapVerify(['required']),
      clearable: true,
      data: options.containerTypeCodeList || [],
      mchange(val, item, cb) {
        if (val) {
          cb({
            containerTypeName: options.containerTypeList.find(
              (item) => String(item.value) === String(val)
            ).value
          })
          if (flag) {
            that.$emit(
              'getGoodsPositionNumList',
              options.containerTypeList.find(
                (item) => String(item.value) === String(val)
              ).value
            )
          }
        } else {
          that.$emit('getGoodsPositionNumList')
          cb({ containerTypeName: '', goodsPositionNum: [] })
        }
      }
    },
    // 容器类型名称
    {
      name: 'containerTypeName',
      value: '',
      span: 20,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.containerTypeName'),
      clearable: true,
      data: options.containerTypeList || [],
      mchange(val, item, cb) {
        if (val) {
          cb({
            containerTypeId: options.containerTypeCodeList.find(
              (item) => String(item.value) === String(val)
            ).value
          })
          if (flag) {
            that.$emit(
              'getGoodsPositionNumList',
              options.containerTypeList.find(
                (item) => String(item.value) === String(val)
              ).value
            )
          }
        } else {
          that.$emit('getGoodsPositionNumList')
          cb({ containerTypeId: '', goodsPositionNum: [] })
        }
      }
    }
  ]
  if (flag) {
    containerTypeFilterFormData.push({
      name: 'goodsPositionNum',
      value: [],
      span: 20,
      component: 'elSelect',
      multiple: true,
      label: that.$t('lang.gles.strategy.containerGoodsPositionNum'),
      clearable: true,
      data: options.goodPositionNumList || []
    })
  }
  return containerTypeFilterFormData
}
// 容器类型过滤table
export const getFilterTableData = (that) => {
  const isGoodsPositionNum =
    (that.formModel.targetTypeCode === 12 &&
      (that.formModel.targetRangeCode === 10 ||
        that.formModel.targetRangeCode === 11)) ||
    that.formModel.targetRangeCode === 14

  const filterTableData = [
    {
      isShow: true,
      prop: 'containerTypeId',
      label: that.$t('lang.gles.strategy.containerTypeCode'),
      component: 'elSelect',
      rules: mapVerify(['required']),
      data: that.cacheContainerTypeCodeList || [],
      watch(val, rowData, index, tableRef) {
        if (val) {
          const containerTypeName = that.cacheContainerTypeList.find(
            (item) => String(item.value) === String(val)
          ).value
          tableRef.setRowItemDataByIndex(index, {
            containerTypeName,
            goodsPositionNum: []
          })
          isGoodsPositionNum && that.getGoodsPositionNumList(containerTypeName)
        } else {
          isGoodsPositionNum && that.getGoodsPositionNumList()
          tableRef.setRowItemDataByIndex(index, {
            containerTypeName: '',
            goodsPositionNum: []
          })
        }
      },
      formatter(row, column) {
        return filterFormater(that.cacheContainerTypeCodeList, row[column])
      }
    },
    {
      isShow: true,
      prop: 'containerTypeName',
      label: that.$t('lang.gles.strategy.containerTypeName'),
      component: 'elSelect',
      rules: mapVerify(['required']),
      data: that.cacheContainerTypeList || [],
      watch(val, rowData, index, tableRef) {
        if (val) {
          const containerTypeId = that.cacheContainerTypeCodeList.find(
            (item) => String(item.value) === String(val)
          ).value
          tableRef.setRowItemDataByIndex(index, {
            containerTypeId,
            goodsPositionNum: []
          })
          isGoodsPositionNum && that.getGoodsPositionNumList(containerTypeId)
        } else {
          isGoodsPositionNum && that.getGoodsPositionNumList()
          tableRef.setRowItemDataByIndex(index, { containerTypeId: '' })
        }
      },
      formatter(row, column) {
        return filterFormater(that.cacheContainerTypeList, row[column])
      }
    }
  ]
  if (isGoodsPositionNum) {
    filterTableData.push({
      isShow: true,
      multiple: true,
      prop: 'goodsPositionNum',
      label: that.$t('lang.gles.base.goodsPositionNum'),
      component: 'elSelect',
      data: that.goodPositionNumList || [],
      formatter(row, column) {
        if (Array.isArray(row[column])) {
          return row[column].join(',')
        }
        return row[column]
      }
    })
  }
  return filterTableData
}

// 可编辑子table的form表单 ---容器过滤类型 (TODO: 废弃)
export const editFilterFormData = (that) => {
  const flag =
    (that.formModel.targetTypeCode === 12 &&
      (that.formModel.targetRangeCode === 10 ||
        that.formModel.targetRangeCode === 11)) ||
    that.formModel.targetRangeCode === 14
  const editFiterFormData = [
    // 容器类型编码
    {
      name: 'containerTypeId',
      value: '',
      component: 'elSelect',
      clearable: true,
      data: that.containerTypeCodeList || [],
      mchange(val, index, row, cb) {
        // console.log(val,cb,"000000",that.containerTypeList.find(item => String(item.value) === String(val)).value)
        if (val) {
          // cb([{ name: 'containerTypeName', index, value: '3' }, { name: 'goodsPositionNum', index, value: [] }])
          cb([
            {
              name: 'containerTypeName',
              index,
              value: that.containerTypeList.find(
                (item) => String(item.value) === String(val)
              ).value
            }
          ])
          if (flag) {
            that.$emit(
              'getGoodsPositionNumList',
              that.containerTypeList.find(
                (item) => String(item.value) === String(val)
              ).value
            )
          }
        } else {
          cb([
            { name: 'containerTypeName', index, value: '' },
            { name: 'goodsPositionNum', index, value: [] }
          ])
        }
      }
    },
    {
      name: 'containerTypeName',
      value: '',
      component: 'elSelect',
      clearable: true,
      data: that.containerTypeList || [],
      mchange(val, index, row, cb) {
        if (val) {
          console.log(
            Number(
              that.containerTypeCodeList.find(
                (item) => String(item.value) === String(val)
              ).value
            )
          )
          cb([
            {
              name: 'containerTypeId',
              index,
              value: String(
                that.containerTypeCodeList.find(
                  (item) => String(item.value) === String(val)
                ).value
              )
            }
          ])
          if (flag) {
            that.$emit(
              'getGoodsPositionNumList',
              that.containerTypeList.find(
                (item) => String(item.value) === String(val)
              ).value
            )
          }
        } else {
          cb([
            { name: 'containerTypeId', index, value: '' },
            { name: 'goodsPositionNum', index, value: [] }
          ])
        }
      }
    }
  ]
  if (flag) {
    editFiterFormData.push({
      name: 'goodsPositionNum',
      value: '',
      component: 'elSelect',
      clearable: true,
      multiple: true,
      data: that.goodPositionNumList || []
    })
  }
  return editFiterFormData
}
