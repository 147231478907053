<template>
  <div class="basic-data-add">
    <el-card class="add-card">
      <div class="form_title b mb15">
        {{ title }}{{ $t("lang.gles.strategy.applicationConfig") }}
      </div>
      <div class="basicInfo">
        <el-divider content-position="left">
          {{ $t("lang.gles.common.basicInfo") }}
        </el-divider>
        <m-form
          ref="myForm"
          :form-data="basicFormData"
          label-position="right"
          :label-width="120"
          :extend-config="extendFormConfig"
        >
          <template #inputCodeTipIcon>
            <el-tooltip
              popper-class="workshop_code_tip-icon"
              effect="dark"
              :content="$t(autoGeneCodeTip)"
              placement="right"
            >
              <i class="el-icon-tip el-icon-question tip" />
            </el-tooltip>
          </template>
        </m-form>
      </div>
      <!-- 匹配条件 -->
      <div class="targetRange">
        <el-divider content-position="left">
          {{ $t("lang.gles.strategy.matchCondition") }}
        </el-divider>
        <m-form
          ref="myMatchForm"
          :form-data="matchConditionFormData"
          label-position="right"
          :label-width="120"
          :extend-config="extendFormConfig"
        />
      </div>
      <!-- 应用策略 -->
      <div class="appStrategy">
        <el-divider content-position="left">
          {{ $t("lang.gles.strategy.applicationStrategy") }}
        </el-divider>
        <div class="btns ml30">
          <el-button
            type="text"
            icon="el-icon-circle-plus"
            @click="addStrategy"
          >
            {{ $t("lang.gles.strategy.add") }}
          </el-button>
          <el-button
            type="text"
            style="color: #f56c6c"
            icon="el-icon-delete-solid"
            @click="batchDel"
          >
            {{ $t("lang.gles.common.batchDelete") }}
          </el-button>
          <m-edit-table
            ref="addStrategyEditTable"
            border
            :table-data="strategyTableData"
            :extend-config="extendTableConfig"
            :table-item="strategyTableItem"
            :form-data="strategyTableFormData"
            :page-data="null"
            @delSubRow="delSubRow"
            @mEditTableEditRow="editRow"
            @upRow="upRow"
            @downRow="downRow"
            @selection-change="(val) => (selectStrategy = val)"
          />
        </div>
      </div>
      <div class="footer-btns">
        <el-button @click="onCancel">
          {{ $t("lang.gles.common.cancel") }}
        </el-button>
        <el-button v-debounce="onSubmit" type="primary">
          {{ $t("lang.gles.common.confirm") }}
        </el-button>
      </div>
    </el-card>
    <!-- 新增策略弹窗 -->
    <add-strategy ref="addStrategy" @addStrategy="updateStrategyList" />
  </div>
</template>
<script>
import MForm from '@/libs_sz/components/MForm/MForm'
import addMixins from '@/mixins/addMixins'
import addCofigMixins from '@/views/strategyManage/addConfigMinixs.js'
import {
  getEditBaseFormData,
  getMatchConditionFormData,
  getStrategyTableData,
  editStrategyFormData
} from '../data'
import AddStrategy from './addStrategy.vue'

export default {
  name: 'AddAppConfig',
  components: {
    MForm,
    AddStrategy
  },
  mixins: [addMixins, addCofigMixins],
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => { }
    },
    options: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      title: this.mode === 'add' ? this.$t('lang.gles.common.addNew') : this.mode === 'edit' ? this.$t('lang.gles.common.edit') : this.$t('lang.gles.common.copy'),
      strategyTableData: [],
      // form表单相关的
      extendFormConfig: {
        isNeedBtn: false
      },
      extendTableConfig: {
        sortNum: true,
        checkBox: true,
        executeSort: true,
        operate: [
          {
            event: 'upRow',
            label: this.$t('lang.gles.strategy.moveUp')
          },
          {
            event: 'downRow',
            label: this.$t('lang.gles.strategy.moveDown')
          },
          // {
          //   event: 'mEditTableEditRow',
          //   label: this.$t('lang.gles.common.edit')
          // },
          {
            event: 'delSubRow',
            label: this.$t('lang.gles.common.delete'),
            confirm: true,
            confirmMessage: this.$t('lang.gles.common.deleteConfirmMsg0'),
            customClass: 'danger'
          }
        ]
      },
      selectStrategy: [],
      strategyCode_dict: [],
      strategy_dict: []
    }
  },
  computed: {
    basicFormData() {
      const row = this.rowDetail || {}
      return getEditBaseFormData(this, { ...row })
    },
    matchConditionFormData() {
      const row = this.rowDetail || {}
      return getMatchConditionFormData(this, this.options, { ...row })
    },
    strategyTableItem() {
      return getStrategyTableData(this, this.options)
    },
    strategyTableFormData() {
      return editStrategyFormData(this, this.options)
    }
  },
  async created() {
    await this.getStrategyList()
    if (this.mode === 'edit' || this.mode === 'copy') {
      const row = this.rowDetail
      this.getShelfStrategyConfigDetail(row.id)
      // 更新级联的下拉数据
      this.initEditSelectList(row)
      return
    }
    this.initSelectList()
  },
  methods: {
    // edit或copy 下获取详情
    async getShelfStrategyConfigDetail(id) {
      const { code, data } = await this.$httpService(
        this.$apiStore.strategyManage('queryConfigDetail'),
        { id }
      )
      if (code) return
      // 回显
      // console.log(data, 'data09000000')
      this.strategyTableData = data.strategyId
        ? data.strategyId.split(',').map((item, index) => {
          return {
            executeSort: index + 1,
            strategyCode: Number(item),
            strategyName: Number(item),
            remark: this.strategy_dict.find(cv => cv.value === Number(item)).remark || ''
          }
        })
        : []
    },
    // 获取策略的下拉数据
    async getStrategyList() {
      const { code, data } = await this.$httpService(
        this.$apiStore.strategyManage('queryShelfOptions')
      )
      if (code) return
      this.strategy_dict = data.map((item) => {
        return { label: item.strategyName, value: item.id, remark: item.remark }
      })
      this.strategyCode_dict = data.map((item) => {
        return { label: item.strategyCode, value: item.id }
      })
    },
    // 添加策略
    addStrategy() {
      // 添加新的策略之前过滤掉已添加的
      const strtegyIds = this.strategyTableData.map((item) => item.strategyCode)
      strtegyIds.length > 0 &&
        strtegyIds.forEach((item) => {
          this.strategy_dict = this.strategy_dict.filter(
            (cv) => cv.value !== item
          )
          this.strategyCode_dict = this.strategyCode_dict.filter(
            (cv) => cv.value !== item
          )
        })
      // console.log( "===",this.strategy_dict)
      const options = {
        strategy_dict: this.strategy_dict,
        strategyCode_dict: this.strategyCode_dict
      }
      this.$refs.addStrategy.init(options)
    },
    updateStrategyList(val) {
      this.strategyTableData.push({ ...val })
    },
    // 批量删除
    batchDel() {
      const selectData = this.selectStrategy
      if (selectData.length < 1) {
        this.$message({
          type: 'error',
          message: this.$t('lang.gles.strategy.atLeastOneRecordCouldBeDelete')
        })
        return
      }
      selectData.forEach((item) => {
        delete item.isEdit
        delete item.isNew
        const findIndex = this.strategyTableData.findIndex((c) => {
          return JSON.stringify(c) === JSON.stringify(item)
        })
        // 清楚当前的tableData
        this.strategyTableData.splice(findIndex, 1)
      })
      this.strategy_dict = this.options.strategy_dict
      this.strategyCode_dict = this.options.strategyCode_dict
    },
    // 删除子项目
    delSubRow({ $index }) {
      this.strategyTableData.splice($index, 1)
      this.strategy_dict = this.options.strategy_dict
      this.strategyCode_dict = this.options.strategyCode_dict
      // this.$refs.subEditTable.removeRow(row)
    },
    editRow() { },
    // 上移，下移
    upRow({ $index, row }) {
      if ($index > 0) {
        const upDate = this.strategyTableData[$index - 1]
        this.strategyTableData.splice($index - 1, 1)
        this.strategyTableData.splice($index, 0, upDate)
      } else {
        this.$message({
          type: 'warning',
          message: this.$t('lang.gles.strategy.firstRecordNotBeMoveUp')
        })
      }
    },
    downRow({ $index, row }) {
      if ($index + 1 === this.strategyTableData.length) {
        this.$message({
          type: 'warning',
          message: this.$t('lang.gles.strategy.lastRecordNotBeMoveUp')
        })
      } else {
        const downDate = this.strategyTableData[$index + 1]
        this.strategyTableData.splice($index + 1, 1)
        this.strategyTableData.splice($index, 0, downDate)
      }
    },
    onSubmit() {
      console.log(this.mode, 'mode')
      this.$refs.myForm.getValidateFormModel().then(async(model) => {
        this.$refs.myMatchForm
          .getValidateFormModel()
          .then(async(matchFormModel) => {
            const params = { ...this.rowDetail, ...model, ...matchFormModel }
            if (this.$refs.addStrategyEditTable) {
              params.strategyId = this.$refs.addStrategyEditTable
                .getTableData()
                .map((item) => item.strategyCode)
                .join(',')
            }
            // console.log(params, 'params')
            if (this.mode === 'copy') {
              delete params.id
            }
            if (this.mode === 'edit' || this.mode === 'copy') {
              delete params.createTime
              delete params.createUser
              delete params.updateTime
              delete params.updateUser
            }
            this.mode === 'edit'
              ? await this.update(params)
              : await this.insert(params)
          })
      })
    },
    async insert(params) {
      const { code } = await this.$httpService(
        this.$apiStore.strategyManage('addStrategyConfig'),
        params
      )
      if (code) return
      this.mode === 'add'
        ? this.$message.success(this.$t('lang.gles.common.addSuccessfully'))
        : this.$message.success(this.$t('lang.gles.strategy.copySuccessfully'))
      this.onCancel()
    },
    async update(params) {
      const { code } = await this.$httpService(
        this.$apiStore.strategyManage('updateConfig'),
        params
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.updateSuccessfully'))
      this.onCancel()
    },
    // 取消
    onCancel() {
      this.$emit('updateCom', {
        currentCom: 'appConfigList',
        mode: ''
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.form_title {
  border-left: 4px solid #409eff;
  height: 18px;
  line-height: 20px;
  padding-left: 8px;
  font-size: 16px;
}
.tip {
  display: inline-block;
  position: absolute;
  top: 6px;
  right: -24px;
}
.el-card__body {
  display: flex;
  flex-direction: column;
  .footer-btns {
    flex: 0 0 60px;
    left: 201px;
    bottom: 20px;
    z-index: 10;
    width: calc(100vw - 212px);
    height: 60px;
    line-height: 60px;
    margin-top: 10px;
    text-align: center;
  }
}
// /deep/ .el-checkbox__label{
//   display: none;
// }
</style>
